import ACTION_MODE from "constants/alias/ACTION_MODE"
import FILE_ID from "constants/alias/FILE_ID"
import FILE_KEY from "constants/alias/FILE_KEY"
import FILE_RANGE from "constants/alias/FILE_RANGE"
import FILE_SCALE from "constants/alias/FILE_SCALE"
import FILENAME from "constants/alias/FILENAME"
import FILEREF from "constants/alias/FILEREF"
import PRINT_MODE from "constants/alias/PRINT_MODE"
import SIZE from "constants/alias/SIZE"
import TRUNCATED from "constants/alias/TRUNCATED"
import SWITCH_FILE_PREPRESS from "constants/SWITCH_FILE_PREPRESS"
import submit_action from "js/comp/files/actions/submit_action"
import ensure_pdf_informations from "js/files/actions/ensure_pdf_informations"
import wait_files from "js/files/actions/wait_files"
import new_file from "js/files/make/new_file"
import get_django_url from "js/utils/get_django_url"
import length from "rfuncs/functions/length"
import object_map from "rfuncs/functions/object_map"
import scan from "rfuncs/functions/scan"
import values from "rfuncs/functions/values"
import imposition_plot from "workflow/actions/imposition_plot"
import add_b64_to_url from "workflow/tasks/plot/utils/add_b64_to_url"
import get_measures from "workflow/units/get_measures"
import to_cm from "workflow/units/to_cm"

const is_single_page = (f, is_true, is_false) =>
    length(f.pdf_informations.trim_box) == 1 ? is_true : is_false

export const submit_prepress_files = files =>
    submit_action(
        SWITCH_FILE_PREPRESS,
        object_map(
            f => f.name,
            f => f,
            files
        )
    )
export const make_prepress_url_from_file = (f, print_mode, processor) => {
    const get_size_input = f => {
        const page = f.pdf_informations.trim_box[1]

        if (!page) return ""

        const { width, height } = get_measures(page, to_cm)

        return `${width}x${height} cm`
    }

    return ensure_pdf_informations([f], f =>
        get_django_url(
            print_mode ? "imposition_prepress_blank" : "imposition_prepress"
        )
            .then(url =>
                add_b64_to_url(url, {
                    [FILE_KEY]: [
                        {
                            [FILE_ID]: f.short_name,
                            [FILE_SCALE]: "sketch",
                            [FILE_RANGE]: is_single_page(f, "1", "1-2")
                        }
                    ],
                    [SIZE]: get_size_input(f),
                    [ACTION_MODE]: "single_pdf",
                    [TRUNCATED]: "off",
                    [FILENAME]: f.basename,
                    [FILEREF]: f.short_name,
                    [PRINT_MODE]: print_mode || is_single_page(f, "f", "fr")
                })
            )
            .then(processor)
    )
}

export default async (print_mode, files_dict) => {
    const files = []

    for (const f of await wait_files(values(files_dict))) {
        await make_prepress_url_from_file(f, print_mode, imposition_url =>
            imposition_plot({
                imposition_url,
                filenames: { [f.short_name]: f.url }
            }).then(all => scan(f => files.push(new_file(f)), all))
        )
    }

    return submit_prepress_files(files)
}
