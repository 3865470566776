import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "btn-toolbar" }
const _hoisted_3 = ["download", "href", "title", "onClick"]
const _hoisted_4 = ["name", "value"]
const _hoisted_5 = ["disabled", "title", "onClick"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 2,
  class: "small fst-italic ms-1 text-muted"
}
const _hoisted_10 = {
  key: 2,
  class: "input-group input-group-sm ms-auto"
}
const _hoisted_11 = ["title"]
const _hoisted_12 = ["title"]
const _hoisted_13 = ["id", "accept", "multiple"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_task_progress = _resolveComponent("task_progress")

  return (_openBlock(), _createElementBlock("label", {
    class: _normalizeClass(["btn btn-outline-light text-start w-100 pe-2", {
            'bg-white': $options.length($data.files) + $options.length(_ctx.available_files),
            'ps-2': $options.length($data.files)
        }]),
    for: $data.id,
    onDrop: _cache[4] || (_cache[4] = _withModifiers((...args) => ($options.create_file && $options.create_file(...args)), ["prevent","stop"]))
  }, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sorted_files, (file) => {
        return (_openBlock(), _createElementBlock("div", {
          key: file.id,
          class: "input-group input-group-sm me-2"
        }, [
          _createElementVNode("a", {
            class: _normalizeClass(["btn p-0 px-1", 
                        $options.guess_filecheck(file.short_name)
                            ? 'btn-success text-white'
                            : file.invalid
                              ? 'btn-danger  text-white'
                              : 'btn-light'
                    ]),
            download: file.short_name,
            href: file.url,
            target: "_blank",
            title: file.short_name,
            onClick: _withModifiers($event => ($options.open_file(file)), ["prevent","stop"])
          }, _toDisplayString($options.truncate_chars(file.short_name)), 11 /* TEXT, CLASS, PROPS */, _hoisted_3),
          (file.remote_name)
            ? (_openBlock(), _createElementBlock("input", {
                key: 0,
                name: $props.field,
                type: "hidden",
                value: file.remote_name
              }, null, 8 /* PROPS */, _hoisted_4))
            : _createCommentVNode("v-if", true),
          _createElementVNode("a", {
            class: _normalizeClass(["btn p-0 px-1", 
                        $options.guess_filecheck(file.short_name)
                            ? 'btn-success text-white'
                            : file.invalid
                              ? 'btn-danger  text-white'
                              : 'btn-light'
                    ]),
            disabled: $data.upload_task,
            title: _ctx.translations.actions.DELETE,
            onClick: _withModifiers($event => ($options.delete_file(file.id)), ["prevent","stop"])
          }, " × ", 10 /* CLASS, PROPS */, _hoisted_5)
        ]))
      }), 128 /* KEYED_FRAGMENT */)),
      ($options.length($data.files) == 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _cache[5] || (_cache[5] = _createElementVNode("i", { class: "glyphicon glyphicon-file me-1" }, null, -1 /* HOISTED */)),
            ($props.multiple)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.translations.actions.DROP_FILES), 1 /* TEXT */))
              : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.translations.actions.DROP_FILE), 1 /* TEXT */)),
            (!_ctx.is_staff)
              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.translations.actions.DROP_OR_CLICK), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.is_loading)
        ? (_openBlock(), _createBlock(_component_task_progress, { key: 1 }))
        : ($options.length(_ctx.available_files) > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", {
                class: "btn btn-sm btn-outline-light p-0 px-1",
                onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.add_available_files && $options.add_available_files(...args)), ["prevent","stop"]))
              }, _toDisplayString(_ctx.translations.actions.ADD) + " " + _toDisplayString(`${$options.length(_ctx.available_files)} file`), 1 /* TEXT */),
              _createElementVNode("div", {
                class: "btn btn-sm btn-outline-light p-0 px-1",
                title: _ctx.translations.actions.DELETE,
                onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.delete_available_files && $options.delete_available_files(...args)), ["prevent","stop"]))
              }, " × ", 8 /* PROPS */, _hoisted_11)
            ]))
          : ($options.length($data.files) > 0)
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: "btn btn-outline-light p-0 px-1 border-0 ms-auto",
                title: _ctx.translations.actions.DELETE,
                onClick: _cache[2] || (_cache[2] = _withModifiers($event => ($options.delete_file()), ["prevent","stop"]))
              }, " × ", 8 /* PROPS */, _hoisted_12))
            : _createCommentVNode("v-if", true)
    ]),
    _withDirectives(_createElementVNode("input", {
      id: $data.id,
      ref: "file",
      accept: $props.accept,
      multiple: $props.multiple ? true : false,
      type: "file",
      onChange: _cache[3] || (_cache[3] = _withModifiers((...args) => ($options.create_file && $options.create_file(...args)), ["prevent"]))
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_13), [
      [_vShow, false]
    ])
  ], 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_1))
}