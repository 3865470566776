<template>
    <div ref="container" class="onhover" style="position: relative">
        <textarea
            ref="main"
            v-model="content_mutable"
            class="form-control"
            :name="name"
            :placeholder="placeholder"
            style="min-height: 200px"
        />
        <div
            class="onhover-show-visibility border border-top-0 border-end-0 bg-white ps-2 pe-2"
            style="position: absolute; top: 1px; right: 1px; cursor: pointer"
        >
            <a class="small text-muted clickable" @click.prevent="open_editor"
                >{{ translations.actions.CHANGE }} &rarr;</a
            >
        </div>
    </div>
</template>

<script>
import navigate from "js/routes/navigate"
import { MARKDOWN_VIEW } from "js/routes/urls"
import make_translations from "workflow/utils/make_translations"

export default {
    data() {
        return {
            content_mutable: this.content
        }
    },
    props: ["id", "name", "content", "cols", "rows", "placeholder"],
    methods: {
        open_editor() {
            navigate(MARKDOWN_VIEW, {
                selector: `#${this.$refs.container.id} textarea`
            })
        }
    },
    computed: {
        translations: () => make_translations("actions")
    }
}
</script>
