import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "content flex-grow-1 main-menu" }
const _hoisted_2 = { class: "container position-relative d-flex flex-column h-100" }
const _hoisted_3 = {
  key: 0,
  class: "d-flex justify-content-center"
}
const _hoisted_4 = { class: "btn-group" }
const _hoisted_5 = { class: "btn btn-outline-primary disabled" }
const _hoisted_6 = {
  class: "btn btn-primary",
  type: "submit"
}
const _hoisted_7 = {
  key: 1,
  class: "container text-center"
}
const _hoisted_8 = {
  key: 0,
  class: "container jumbotron text-start"
}
const _hoisted_9 = { class: "h1" }
const _hoisted_10 = { class: "nav-line mb-4" }
const _hoisted_11 = { class: "container" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "container d-flex flex-column" }
const _hoisted_14 = { class: "card-header" }
const _hoisted_15 = { class: "list-group list-group-product list-group-flush d-flex flex-column" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = { class: "row align-items-center markdown-compact" }
const _hoisted_18 = { class: "col-lg-9 col-md-8 col-sm-7 col-8" }
const _hoisted_19 = ["innerHTML"]
const _hoisted_20 = ["innerHTML"]
const _hoisted_21 = {
  key: 0,
  class: "col-lg-3 col-md-4 col-sm-5 col-4"
}
const _hoisted_22 = ["src"]
const _hoisted_23 = ["href", "onClick"]
const _hoisted_24 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.is_barcode)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[3] || (_cache[3] = _createElementVNode("span", { class: "glyphicon glyphicon-barcode" }, null, -1 /* HOISTED */)),
                _createTextVNode(" Barcode: " + _toDisplayString(_ctx.barcode_string), 1 /* TEXT */)
              ]),
              _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.translations.actions.SEARCH) + " → ", 1 /* TEXT */)
            ])
          ]))
        : (_ctx.loading.public_pages)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, _cache[4] || (_cache[4] = [
              _createElementVNode("div", { class: "spinner-border muted m-5 p-5" }, null, -1 /* HOISTED */)
            ])))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              ($options.length(_ctx.results) == 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.translations.actions.NO_RESULTS), 1 /* TEXT */),
                    ($data.tag)
                      ? (_openBlock(), _createElementBlock("button", {
                          key: 0,
                          class: "btn btn-secondary",
                          onClick: _cache[0] || (_cache[0] = () => ($data.tag = null))
                        }, _toDisplayString(_ctx.translations.actions.SHOW_ALL) + " → ", 1 /* TEXT */))
                      : _createCommentVNode("v-if", true)
                  ]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", _hoisted_11, [
                        ($options.length(_ctx.grouped_results) > 1)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 0,
                              class: _normalizeClass(["nav-link clickable", !$data.selected ? 'active' : '']),
                              style: { order: 0 },
                              onClick: _cache[1] || (_cache[1] = $event => ($options.searchtab(null)))
                            }, _toDisplayString(_ctx.translations.actions.ALL) + " (" + _toDisplayString($options.length(_ctx.results)) + ") ", 3 /* TEXT, CLASS */))
                          : _createCommentVNode("v-if", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.grouped_results, (inner, group) => {
                          return (_openBlock(), _createElementBlock("a", {
                            key: group,
                            class: _normalizeClass(["nav-link clickable", 
                                    group == $data.selected ||
                                    $options.length(_ctx.grouped_results) == 1
                                        ? 'active'
                                        : ''
                                ]),
                            style: _normalizeStyle({ order: _ctx.tags[group].sequence }),
                            onClick: $event => ($options.searchtab(group))
                          }, _toDisplayString(_ctx.tags[group].name) + " (" + _toDisplayString($options.length(inner)) + ") ", 15 /* TEXT, CLASS, STYLE, PROPS */, _hoisted_12))
                        }), 128 /* KEYED_FRAGMENT */)),
                        _createElementVNode("a", {
                          class: "nav-link clickable ms-auto text-danger",
                          style: { order: 800000 },
                          onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.close && _ctx.close(...args)))
                        }, " × " + _toDisplayString(_ctx.translations.actions.CLOSE), 1 /* TEXT */)
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visible_results, (inner, group) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: group,
                          class: "card shadow-sm row-space",
                          style: _normalizeStyle({ order: _ctx.tags[group].sequence })
                        }, [
                          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.tags[group].name), 1 /* TEXT */),
                          _createElementVNode("div", _hoisted_15, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(inner, (info) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: info.pk,
                                class: _normalizeClass(["list-group-item pe-0", {
                                        selected: $options.contains(_ctx.selection, info.pk),
                                        active: info.pk == _ctx.active_key
                                    }]),
                                style: _normalizeStyle({ order: info.score })
                              }, [
                                _createElementVNode("div", {
                                  class: "h4 d-block d-sm-none list-group-item-heading product-title",
                                  innerHTML: info.unicode
                                }, null, 8 /* PROPS */, _hoisted_16),
                                _createElementVNode("div", _hoisted_17, [
                                  _createElementVNode("div", _hoisted_18, [
                                    _createElementVNode("div", {
                                      class: "h4 d-sm-block d-none list-group-item-heading product-title",
                                      innerHTML: info.unicode
                                    }, null, 8 /* PROPS */, _hoisted_19),
                                    (info.desc)
                                      ? (_openBlock(), _createElementBlock("p", {
                                          key: 0,
                                          class: "list-group-item-text markdown",
                                          innerHTML: info.desc
                                        }, null, 8 /* PROPS */, _hoisted_20))
                                      : _createCommentVNode("v-if", true)
                                  ]),
                                  (info.img)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                        _createElementVNode("img", {
                                          class: "img-fluid",
                                          src: info.img
                                        }, null, 8 /* PROPS */, _hoisted_22)
                                      ]))
                                    : _createCommentVNode("v-if", true)
                                ]),
                                _createElementVNode("a", {
                                  class: "stretched-link",
                                  href: info.url,
                                  onClick: $event => ($options.toggle(info))
                                }, null, 8 /* PROPS */, _hoisted_23)
                              ], 6 /* CLASS, STYLE */))
                            }), 128 /* KEYED_FRAGMENT */))
                          ]),
                          (
                                    $options.length(_ctx.grouped_results[group]) > $data.expand_size
                                )
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                class: "card-footer text-center clickable",
                                onClick: _withModifiers($event => ($options.toggle_expanded(group)), ["stop","prevent"])
                              }, _toDisplayString($options.is_expanded(group)
                                        ? _ctx.translations.actions.HIDE
                                        : _ctx.translations.actions.SHOW_ALL +
                                          " (" +
                                          $options.length(_ctx.grouped_results[group]) +
                                          ")"), 9 /* TEXT, PROPS */, _hoisted_24))
                            : _createCommentVNode("v-if", true)
                        ], 4 /* STYLE */))
                      }), 128 /* KEYED_FRAGMENT */))
                    ])
                  ], 64 /* STABLE_FRAGMENT */))
            ], 64 /* STABLE_FRAGMENT */))
    ])
  ]))
}