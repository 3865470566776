<template>
    <form class="input-group w-100" @submit.stop.prevent="searchsubmit">
        <input
            ref="input"
            autocomplete="off"
            class="form-control"
            :placeholder="full_placeholder"
            type="search"
            :value="query"
            @input="searchinput"
        />
    </form>
</template>

<script>
import close from "js/mixins/close"
import get_route_props from "js/routes/get_route_props"
import navigate from "js/routes/navigate"
import { SEARCH_VIEW } from "js/routes/urls"
import user from "js/state/user"
import keyboard from "js/state/keyboard"

export default {
    mixins: [close],
    props: ["placeholder"],
    methods: {
        searchinput(e) {
            const query = e.target.value

            if (query) {
                navigate(SEARCH_VIEW, { query })
            } else {
                this.close()
            }
        },
        searchsubmit() {
            navigate(SEARCH_VIEW, { query: this.$refs.input.value, send: 1 })
        },
        keypress(e) {
            if (
                (event.metaKey || event.ctrlKey) &&
                event.key.toLowerCase() === "k"
            ) this.$refs.input.focus()
        }
    },
    computed: {
        full_placeholder() {
            return user.is_staff ? `${this.placeholder} (⌘K)` : this.placeholder
        },
        query() {
            if (this.$route.name == SEARCH_VIEW) {
                return get_route_props(this.$route).query
            }
        }
    },
    mounted() {
        keyboard.register(this.keypress)
    }
}
</script>
