import identity from "rfuncs/functions/identity"
import encode from "workflow/utils/encode"

const add_version = s => `${s}@9`

function _check(key = "<__test__>") {
    try {
        localStorage.setItem(key, key)
        localStorage.removeItem(key)
        return true
    } catch (e) {
        return false
    }
}

const _has_local_storage = _check()

const persistence = {
    get: (k, d = null) => {
        if (_has_local_storage) {
            const v = localStorage.getItem(add_version(k))
            return v ? encode.loads(v) : d
        }
    },
    set: (k, v) => {
        if (_has_local_storage) {
            return localStorage.setItem(add_version(k), encode.dumps(v))
        }
    },
    make_encoder(key) {
        return {
            get: (...args) => persistence.get(key, ...args),
            set: (...args) => persistence.set(key, ...args)
        }
    }
}

export default persistence
