import on from "js/dom/on"
import toggle_class from "js/dom/toggle_class"
import register_dom_update from "js/navigation/register_dom_update"

const addscroll = () =>
    toggle_class(document.body, "unscrolled-body", window.scrollY <= 0)

on("scroll", null, addscroll)

register_dom_update({
    after: addscroll
})
