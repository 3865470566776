<template>
    <div class="alert alert-danger m-0">
        {{ translations.errors.BROWSER_ERROR }}
    </div>
</template>

<script>
import make_translations from "workflow/utils/make_translations"

export default {
    props: [],
    computed: {
        translations: () => make_translations("errors")
    }
}
</script>
