import wait_files from "js/files/actions/wait_files"
import identity from "rfuncs/functions/identity"
import get_pdf_informations from "workflow/actions/get_pdf_informations"

export default (files, func = identity) =>
    wait_files(files, async f => {
        if (!f.pdf_informations)
            f.mutate_now(f => {
                f.pdf_informations = get_pdf_informations(f.url)
                return f.pdf_informations.then(() => f.url)
            })
        f.pdf_informations = await f.pdf_informations
        f.invalid = f.pdf_informations.error

        await func(f)
    })
