// prettier-ignore
export default {
    "DOWNLOAD_TEMPLATE_OR_CHECK": "Download Template or check your file",
    "DOWNLOAD_TEMPLATE": "Download template",
    "CHECK_YOUR_FILE": "Check your file",
    "TEMPLATE_FEATURE_1": "Get the product\u2019s layout in PDF or InDesign format.",
    "TEMPLATE_FEATURE_2": "Use it to correctly insert your design.",
    "TEMPLATE_FEATURE_3": "If you\u2019ve got one or more print files you can check them uploading them down here.",
    "TEMPLATE_FEATURE_4": "You can manage the sequence of the pages and adapt the format.",
    "FILE_NAME": "File name",
    "RESIZE": "Resize",
    "SCALE": "Scale",
    "CONTAIN": "Contain",
    "FILL": "Fill",
    "SKETCH": "Deform",
    "BLEED_AREA": "Bleed area",
    "CUT_AREA": "Cut area",
    "EXPECTED_FORMAT": "Expected format",
    "FILE_FORMAT": "File format",
    "PAGES": "Pages",
    "FILE": "File",
    "INFORMATIONS": "Informations",
    "IMPOSITION": "Imposition",
    "FILECHECK": "File check",
    "EXTRA_PAGES": "Extra pages",
    "GUIDES": "Guides",
    "PRINT_FILE": "Print file"
}