import start_request from "js/navigation/start_request"
import virtual from "workflow/vue/3/virtual"

export default virtual({
    methods: {
        push_state(url) {
            console.info("pushing url to history: " + url)
            window.history.pushState({ url }, "", url)
        },
        replace_state(url) {
            console.info("replace url to history: " + url)
            window.history.replaceState({ url }, "", url)
        }
    }
})

window.onpopstate = e => {
    if (!window.location.hash)
        start_request(window.location.href, {
            change_location: false,
            data: null
        })
}
