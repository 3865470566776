import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_double = _resolveComponent("double")

  return (_openBlock(), _createBlock(_component_double, {
    name: $props.name,
    options: $options.options,
    size_1: $props.size_1,
    size_2: $props.size_2,
    value: $props.value
  }, {
    buttons: _withCtx(() => [
      _renderSlot(_ctx.$slots, "buttons")
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["name", "options", "size_1", "size_2", "value"]))
}