// prettier-ignore
export default {
    "DOWNLOAD_TEMPLATE_OR_CHECK": "Scarica Template o verifica file",
    "DOWNLOAD_TEMPLATE": "Scarica template",
    "CHECK_YOUR_FILE": "Verifica File",
    "TEMPLATE_FEATURE_1": "Ottieni l'impaginato del prodotto configurato in formato PDF e InDesign.",
    "TEMPLATE_FEATURE_2": "Usalo per inserire la tua grafica correttamente.",
    "TEMPLATE_FEATURE_3": "Se possiedi uno o pi\u00f9 file di stampa puoi verificarli caricandoli qui sotto.",
    "TEMPLATE_FEATURE_4": "Potrai gestire la sequenza delle pagine e adatterne il formato.",
    "FILE_NAME": "Nome file",
    "RESIZE": "Ridimensiona",
    "SCALE": "Scala",
    "CONTAIN": "Contieni",
    "FILL": "Riempi",
    "SKETCH": "Deforma",
    "BLEED_AREA": "Area di smargine",
    "CUT_AREA": "Area di taglio",
    "EXPECTED_FORMAT": "F.to atteso",
    "FILE_FORMAT": "F.to file",
    "PAGES": "Pagine",
    "FILE": "File",
    "INFORMATIONS": "Informazioni",
    "IMPOSITION": "Imposizione",
    "FILECHECK": "File check",
    "EXTRA_PAGES": "Pagine in eccesso",
    "GUIDES": "Guide",
    "PRINT_FILE": "File di stampa"
}