import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelText as _vModelText, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["disabled", "title"]
const _hoisted_2 = ["value"]
const _hoisted_3 = ["label"]
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]
const _hoisted_6 = ["name"]
const _hoisted_7 = ["disabled", "title"]
const _hoisted_8 = ["label"]
const _hoisted_9 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($options.show_second ? 'input-group' : '')
  }, [
    _withDirectives(_createElementVNode("select", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($options.selection) = $event)),
      class: _normalizeClass(["form-select prevent-submit-onchange", {
                'is-invalid': $options.is_invalid,
                'flex-grow-0': $options.show_second && $props.size_1
            }]),
      disabled: $props.disabled,
      style: _normalizeStyle({
                width: $options.show_second ? $props.size_1 : null
            }),
      title: $options.label
    }, [
      ($options.is_invalid)
        ? (_openBlock(), _createElementBlock("option", {
            key: 0,
            value: $options.selection
          }, _toDisplayString($props.value), 9 /* TEXT, PROPS */, _hoisted_2))
        : _createCommentVNode("v-if", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.options, (inner, label) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: label }, [
          label
            ? (_openBlock(), _createElementBlock("optgroup", {
                key: 0,
                label: label
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(inner, (_, opt) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: $options.make_key(label, opt),
                    value: $options.make_key(label, opt)
                  }, _toDisplayString(opt), 9 /* TEXT, PROPS */, _hoisted_4))
                }), 128 /* KEYED_FRAGMENT */))
              ], 8 /* PROPS */, _hoisted_3))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(inner, (_, opt) => {
                return (_openBlock(), _createElementBlock("option", {
                  key: $options.make_key(label, opt),
                  value: $options.make_key(label, opt)
                }, _toDisplayString(opt), 9 /* TEXT, PROPS */, _hoisted_5))
              }), 128 /* KEYED_FRAGMENT */))
        ], 64 /* STABLE_FRAGMENT */))
      }), 128 /* KEYED_FRAGMENT */))
    ], 14 /* CLASS, STYLE, PROPS */, _hoisted_1), [
      [_vModelSelect, $options.selection]
    ]),
    _withDirectives(_createElementVNode("input", {
      ref: "hidden",
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.current) = $event)),
      name: $props.name,
      type: "hidden"
    }, null, 8 /* PROPS */, _hoisted_6), [
      [_vModelText, $data.current]
    ]),
    ($options.show_second)
      ? _withDirectives((_openBlock(), _createElementBlock("select", {
          key: 0,
          ref: "second",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.current) = $event)),
          class: _normalizeClass(["form-select prevent-submit-onchange", {
                'flex-grow-0': $props.size_2
            }]),
          disabled: $props.disabled || $options.length($options.options_visible) <= 1,
          style: _normalizeStyle({
                width: $props.size_2
            }),
          title: $options.placeholder
        }, [
          _createElementVNode("optgroup", { label: $options.label }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.options_visible, (title, pk) => {
              return (_openBlock(), _createElementBlock("option", {
                key: pk,
                value: pk
              }, _toDisplayString(title), 9 /* TEXT, PROPS */, _hoisted_9))
            }), 128 /* KEYED_FRAGMENT */))
          ], 8 /* PROPS */, _hoisted_8)
        ], 14 /* CLASS, STYLE, PROPS */, _hoisted_7)), [
          [_vModelSelect, $data.current]
        ])
      : _createCommentVNode("v-if", true),
    _renderSlot(_ctx.$slots, "buttons")
  ], 2 /* CLASS */))
}