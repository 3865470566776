import init from "@intercom/messenger-js-sdk"
import * as intercom_methods from "@intercom/messenger-js-sdk"
import register_dom_update from "js/navigation/register_dom_update"
import user from "js/state/user"
import merge from "rfuncs/functions/merge"

let resolve = null

const promise = new Promise(r => (resolve = r))

const intercom_cmd = (cmd, ...args) =>
    promise.then(i => intercom_methods[cmd](...args))

const intercom_update = payload => {
    if (user.is_staff) {
        intercom_cmd("shutdown")
    } else {
        intercom_cmd(
            "update",
            merge(
                {
                    user_id: user.pk,
                    email: user.email,
                    name: user.name
                },
                payload
            )
        )
    }
}

const intercom_shutdown = () => intercom_cmd("shutdown")

const intercom_track = (...args) => {
    intercom_cmd("trackEvent", ...args)
    intercom_update()
}

const make_post_registration_handler = name => (e, event) => {
    if (!event.detail.initial) {
        //intercom_shutdown()
        intercom_track(name, {})
    }
}

register_dom_update({
    login: make_post_registration_handler("login"),
    logout: make_post_registration_handler("logout"),
    signup: make_post_registration_handler("signup")
})

export default {
    load: app_id => resolve(init({ app_id })),
    update: intercom_update,
    shutdown: intercom_shutdown,
    track: intercom_track
}
