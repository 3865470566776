import autocomplete from "js/comp/autocomplete"
import barcode from "js/comp/barcode"
import cart_counter from "js/comp/cart_counter"
import collapsable from "js/comp/collapsable"
import double from "js/comp/double"
import double_autocomplete from "js/comp/double_autocomplete"
import markdown from "js/comp/markdown"
import megamenu from "js/comp/megamenu"
import messages from "js/comp/messages"
import publicsearch from "js/comp/publicsearch"
import rating from "js/comp/rating"
import searchselect from "js/comp/searchselect"
import sizedatalist from "js/comp/sizedatalist"
import uploader from "js/comp/uploader"
import username from "js/comp/username"
import autocomplete_index from "js/constants/autocomplete"
import init from "js/core/init"
import append from "js/dom/append"
import element from "js/dom/element"
import selector from "js/dom/selector"
import can_wasm from "js/features/can_wasm"
import register_dom_update from "js/navigation/register_dom_update"
import navigate from "js/routes/navigate"
import { SLIDESHOW_VIEW } from "js/routes/urls"
import { create_change_action } from "js/routes/views/pdfview/actions"
import state from "js/state/main"
import user from "js/state/user"
import apply from "rfuncs/functions/apply"
import first from "rfuncs/functions/first"
import group_by from "rfuncs/functions/group_by"
import identity from "rfuncs/functions/identity"
import map from "rfuncs/functions/map"
import merge from "rfuncs/functions/merge"
import object_map from "rfuncs/functions/object_map"
import scan from "rfuncs/functions/scan"
import { RouterView as routerview } from "vue-router"
import json from "workflow/utils/json"
import uuid from "workflow/utils/uuid"

function require_staff(func, func2) {
    return el => {
        if (user.is_staff) {
            return (func || identity)(el)
        }
        return (func2 || identity)(el)
    }
}

const components = {
    double: el =>
        init(double, el, {
            size: "70%",
            options: group_by(
                map(
                    el =>
                        apply(
                            (f, s) => ({
                                value: el.value,
                                label:
                                    el.parentNode.getAttribute("label") || "",
                                group: s ? f : f,
                                name: s ? s : ""
                            }),
                            el.innerHTML.split(": ")
                        ),
                    selector("option", el)
                ),
                info => info.label,
                array =>
                    group_by(
                        array,
                        info => info.group,
                        array =>
                            object_map(
                                info => info.value,
                                info => info.name,
                                array
                            )
                    )
            )
        }),
    double_autocomplete: el => init(double_autocomplete, el),
    autocomplete: el =>
        init(autocomplete, el, {
            value:
                json.loads(el.getAttribute("data-initial") || "null") ||
                (el.value ? [{ pk: el.value, unicode: el.value }] : []),
            options: {},
            multiple: el.matches("[multiple]")
        }),

    autocomplete_select: el =>
        autocomplete_index
            .load_autocomplete(el.getAttribute("data-autocomplete"))
            .then(content => {
                const options = {}

                scan(
                    labels =>
                        scan(
                            (unicode, pk) =>
                                (options[pk] = {
                                    pk,
                                    unicode,
                                    group: labels.name
                                }),
                            labels.objects
                        ),
                    content ? content.objects : []
                )

                return init(searchselect, el, {
                    value: el.value,
                    options
                })
            }),

    searchselect: el =>
        init(searchselect, el, {
            value: map(opt => opt.value, selector("option[selected]", el)),
            multiple: el.matches("[multiple]"),
            options: object_map(
                opt => opt.value,
                opt => ({
                    pk: opt.value,
                    unicode: opt.innerHTML,
                    group: opt.parentNode.getAttribute("label") || null
                }),
                selector("option", el)
            )
        }),

    spreadsheet: el =>
        import(
            /* webpackChunkName: "spreadsheet" */ "js/comp/spreadsheet"
        ).then(m =>
            init(m.default, el, {
                errors: json.loads(el.getAttribute("data-errors")),
                schema: json.loads(el.getAttribute("data-schema")),
                input: json.loads(el.getAttribute("value") || "[{}]"),
                name: el.getAttribute("name")
            })
        ),

    barcode: el => init(barcode, el, { value: el.value, name: el.name }),
    publicsearch: el => init(publicsearch, el),
    megamenu: el =>
        init(megamenu, el, {
            limit: Number(el.getAttribute("data-limit") || 0),
            sublimit: Number(el.getAttribute("data-sublimit") || 0),
            preserve_class: true
        }),
    rating: el => init(rating, el),

    collapsable: el =>
        init(collapsable, el, { collapsed: true, preserve_class: true }),
    username: el => init(username, el),
    cart_counter: el => init(cart_counter, el),
    cookielaw: el => {
        el.addEventListener("click", e => {
            e.preventDefault()
            state.cookielaw = "1"
        })
    },
    imageload: el => {
        const strip = () => {
            el.removeAttribute("loading")
        }

        if (el.complete) {
            strip()
        } else {
            el.addEventListener("load", strip)
        }
    },
    toggleclass: el => {
        const classname = el.getAttribute("data-class")
        const target = el.getAttribute("data-target")
        el.addEventListener("click", e =>
            scan(
                e =>
                    e.classList.contains(classname)
                        ? e.classList.remove(classname)
                        : e.classList.add(classname),
                selector(target)
            )
        )
    },
    report: require_staff(el =>
        import(/* webpackChunkName: "report" */ "js/comp/report").then(m =>
            init(m.default, el, {
                report: json.loads(first(selector("script", el)).innerHTML)
            })
        )
    ),
    markdown: require_staff(el =>
        init(markdown, el, { placeholder: el.getAttribute("placeholder") })
    ),

    filelist: el =>
        import(/* webpackChunkName: "history" */ "js/comp/files/filelist").then(
            m =>
                init(m.default, el, {
                    filelist: el.getAttribute("data-filelist")
                })
        ),

    uploader: el =>
        init(uploader, el, {
            filelist: el.getAttribute("data-filelist")
        }),

    pdfviewlink: el =>
        can_wasm()
            ? el.addEventListener("click", e => {
                  const change_url = el.getAttribute("data-changeurl")

                  e.preventDefault()
                  e.stopPropagation()

                  navigate("filecheck", {
                      imposition_url:
                          el.getAttribute("data-imposition") ||
                          el.getAttribute("href").replace(/\/pdf\//g, "/json/"),
                      hide_presets: el.getAttribute("data-hide-presets"),
                      hide_sidebar: el.getAttribute("data-hide-sidebar"),
                      initial_view: el.getAttribute("data-initial-view"),
                      folder: el.getAttribute("data-folder"),
                      submit_button: el.getAttribute("data-submit-button"),
                      submit_action: change_url
                          ? create_change_action(change_url)
                          : null,
                      filelist: el.getAttribute("data-filelist")
                  })
              })
            : el,

    sizedatalist: el => {
        const component = element("div")
        const id = uuid()

        el.setAttribute("list", id)

        append(el.parentNode, component)

        init(
            sizedatalist,
            component,
            merge(
                object_map(
                    k => k,
                    k => json.loads(el.getAttribute(`data-${k}`) || "[]"),
                    ["units", "min", "max"]
                ),
                { autocomplete: el.getAttribute("data-autocomplete"), id: id }
            )
        )
    },

    routerview: el => init(routerview, el),

    slideshow: el => {
        if (!el.getAttribute("href"))
            el.addEventListener("click", e => {
                e.preventDefault()
                e.stopPropagation()

                navigate(SLIDESHOW_VIEW, {
                    selector: el.getAttribute("data-selector"),
                    selected:
                        el.getAttribute("data-slide-src") ||
                        el.getAttribute("src")
                })
            })
    }
}

init(messages)

register_dom_update({
    after: () => {
        scan(el => {
            const func = components[el.getAttribute("data-component")]
            if (func) func(el)
            el.removeAttribute("data-component")
        }, selector("[data-component]"))

        scan(components.searchselect, selector("select[multiple]"))
        scan(components.imageload, selector("[loading=lazy]"))
    },
    login: () =>
        user.is_staff
            ? import(
                  /* webpackChunkName: "admin" */ "js/comp/files/filesink"
              ).then(m => init(m.default))
            : null
})
