import keyboard from "js/constants/keyboard"
import map from "rfuncs/functions/map"
import object_map from "rfuncs/functions/object_map"
import scan from "rfuncs/functions/scan"
import set_property from "workflow/vue/3/set_property"
import virtual from "workflow/vue/3/virtual"

const callbacks = []

const key_state = virtual({
    data: () => map(() => false, keyboard),
    methods: {
        register: f => callbacks.push(f),
        dispatch: e => scan(f => f(e), callbacks)
    },
})
const reverse_keyboard = object_map(
    v => v,
    (v, k) => k,
    keyboard
)
const set_to = value => event => {
    const prop = reverse_keyboard[event.keyCode]

    if (prop) set_property(key_state, prop, value)

    key_state.dispatch(event)
}

export default key_state

window.addEventListener("keydown", set_to(true))
window.addEventListener("keyup", set_to(false))
