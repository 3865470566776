import register_dom_update from "js/navigation/register_dom_update"
import navigate from "js/routes/navigate"
import { DEFAULT_VIEW } from "js/routes/urls"
import urls from "js/routes/urls"
import state from "js/state/main"
import values from "rfuncs/functions/values"
import { createRouter, createWebHashHistory } from "vue-router"

const router = createRouter({
    history: createWebHashHistory(),
    routes: values(urls)
})

const remove_hash = () =>
    history.replaceState(
        "",
        document.title,
        window.location.pathname + window.location.search
    )

router.afterEach((to, from) => {
    const start = from.name || DEFAULT_VIEW
    const end = to.name || DEFAULT_VIEW

    if (end == DEFAULT_VIEW) remove_hash()

    if (start == end) {
        if (start != DEFAULT_VIEW) state.track_event(`${start}_update`, start)
    } else {
        if (start != DEFAULT_VIEW) state.track_event(`${start}_close`, start)
        if (end != DEFAULT_VIEW) state.track_event(`${end}_start`, end)
    }
})

register_dom_update({
    before: () => navigate()
})

export default router
