<template>
    <a
        ref="link"
        class="onhover text-nowrap main-megamenu clickable"
        :href="url || '/'"
        tabindex="-1"
        @blur="close"
        @click.prevent.stop="menu_click"
        @mouseover="menu_hover"
    >
        <span v-html="content"></span>
    </a>
</template>

<script>
import autocomplete from "js/constants/autocomplete"
import start_request from "js/navigation/start_request"
import get_route_props from "js/routes/get_route_props"
import navigate from "js/routes/navigate"
import { MENU_VIEW } from "js/routes/urls"
import filter from "rfuncs/functions/filter"
import is_array from "rfuncs/functions/is_array"
import length from "rfuncs/functions/length"
import proxy from "workflow/utils/proxy"

export default {
    props: {
        url: String,
        content: String
    },
    methods: {
        menu_hover() {
            this.pages_descendants_by_url
        },
        menu_click() {
            if (this.is_active(this.url)) return navigate()

            if (!this.url) return navigate(MENU_VIEW)

            const action = () =>
                length(this.descendants(this.url)) > 0
                    ? navigate(MENU_VIEW, { url: this.url })
                    : start_request(this.url, {})

            if (is_array(this.descendants(this.url))) {
                action()
            } else {
                autocomplete.promise.public_pages.then(() =>
                    this.$nextTick(() => action())
                )
            }
        },
        is_active(url) {
            return (
                this.$route.name === "menu" &&
                (!url || get_route_props(this.$route).url == url)
            )
        },
        descendants(url) {
            if (url && this.pages_descendants_by_url) {
                return filter(
                    p => p.in_navigation,
                    this.pages_descendants_by_url[url] || []
                )
            }
        }
    },
    computed: proxy(autocomplete, ["pages_descendants_by_url"])
}
</script>
