<template></template>
<script>
import { dismiss_dropdowns } from "js/core/bootstrap"
import toggle_class from "js/dom/toggle_class"
import navigate from "js/routes/navigate"
import keyboard from "js/state/keyboard"
import proxy from "workflow/utils/proxy"

export default {
    watch: {
        escape_pressed(v) {
            dismiss_dropdowns()
            this.close()
        }
    },
    methods: {
        close() {
            navigate()
        }
    },
    computed: proxy(keyboard, { ESCAPE: "escape_pressed" }),
    mounted() {
        this.toggled_class
            ? toggle_class(document.body, { [this.toggled_class]: true })
            : null
    },
    unmounted() {
        this.toggled_class
            ? toggle_class(document.body, { [this.toggled_class]: false })
            : null
    }
}
</script>
