// prettier-ignore
export default {
    "UPLOAD": "Carico ${task_done}/${task_total} file: ${parseInt(done / 1000000)}/${parseInt(total / 1000000)} mb",
    "DOWNLOAD": "Scarico ${task_done}/${task_total} file: ${parseInt( done / (total > 1000000 ? 1000000 : 1000))}/${parseInt(total / (total > 1000000 ? 1000000 : 1000))} ${(total > 1000000 ? 'mb' : 'kb')}",
    "COMPRESS": "Comprimo: ${done}/${total} file",
    "FETCH": "Apro pagine: ${done}/${total}",
    "JSON_FILE": "Carico template: ${done}/${total}",
    "PLOT_PDF": "Creo PDF: ${done}/${total} pagine",
    "MERGE_PDF": "Unisco PDF: ${done}/${total} pagine",
    "SPLIT_PDF": "Divido PDF: ${done}/${total} pagine",
    "PARSE_PDF": "Elaboro PDF: ${done}/${total} file",
    "RASTERIZE_PDF": "Creo anteprima PDF: ${done}/${total} pagine"
}