<template>
    <div class="content flex-grow-1 main-menu">
        <div class="container position-relative d-flex flex-column h-100">
            <div v-if="is_barcode" class="d-flex justify-content-center">
                <div class="btn-group">
                    <div class="btn btn-outline-primary disabled">
                        <span class="glyphicon glyphicon-barcode"></span>
                        Barcode:
                        {{ barcode_string }}
                    </div>
                    <button class="btn btn-primary" type="submit">
                        {{ translations.actions.SEARCH }} &rarr;
                    </button>
                </div>
            </div>
            <div v-else-if="loading.public_pages" class="container text-center">
                <div class="spinner-border muted m-5 p-5"></div>
            </div>
            <template v-else>
                <div
                    v-if="length(results) == 0"
                    class="container jumbotron text-start"
                >
                    <div class="h1">
                        {{ translations.actions.NO_RESULTS }}
                    </div>
                    <button
                        v-if="tag"
                        class="btn btn-secondary"
                        @click="() => (tag = null)"
                    >
                        {{ translations.actions.SHOW_ALL }} &rarr;
                    </button>
                </div>

                <template v-else>
                    <div class="nav-line mb-4">
                        <div class="container">
                            <a
                                v-if="length(grouped_results) > 1"
                                class="nav-link clickable"
                                :class="!selected ? 'active' : ''"
                                :style="{ order: 0 }"
                                @click="searchtab(null)"
                            >
                                {{ translations.actions.ALL }} ({{
                                    length(results)
                                }})
                            </a>

                            <a
                                v-for="(inner, group) in grouped_results"
                                :key="group"
                                class="nav-link clickable"
                                :class="
                                    group == selected ||
                                    length(grouped_results) == 1
                                        ? 'active'
                                        : ''
                                "
                                :style="{ order: tags[group].sequence }"
                                @click="searchtab(group)"
                            >
                                {{ tags[group].name }} ({{ length(inner) }})
                            </a>

                            <a
                                class="nav-link clickable ms-auto text-danger"
                                :style="{ order: 800000 }"
                                @click="close"
                            >
                                &times; {{ translations.actions.CLOSE }}
                            </a>
                        </div>
                    </div>

                    <div class="container d-flex flex-column">
                        <div
                            v-for="(inner, group) in visible_results"
                            :key="group"
                            class="card shadow-sm row-space"
                            :style="{ order: tags[group].sequence }"
                        >
                            <div class="card-header">
                                {{ tags[group].name }}
                            </div>
                            <div
                                class="list-group list-group-product list-group-flush d-flex flex-column"
                            >
                                <div
                                    v-for="info in inner"
                                    :key="info.pk"
                                    class="list-group-item pe-0"
                                    :class="{
                                        selected: contains(selection, info.pk),
                                        active: info.pk == active_key
                                    }"
                                    :style="{ order: info.score }"
                                >
                                    <div
                                        class="h4 d-block d-sm-none list-group-item-heading product-title"
                                        v-html="info.unicode"
                                    ></div>
                                    <div
                                        class="row align-items-center markdown-compact"
                                    >
                                        <div
                                            class="col-lg-9 col-md-8 col-sm-7 col-8"
                                        >
                                            <div
                                                class="h4 d-sm-block d-none list-group-item-heading product-title"
                                                v-html="info.unicode"
                                            ></div>

                                            <p
                                                v-if="info.desc"
                                                class="list-group-item-text markdown"
                                                v-html="info.desc"
                                            ></p>
                                        </div>
                                        <div
                                            v-if="info.img"
                                            class="col-lg-3 col-md-4 col-sm-5 col-4"
                                        >
                                            <img
                                                class="img-fluid"
                                                :src="info.img"
                                            />
                                        </div>
                                    </div>

                                    <a
                                        class="stretched-link"
                                        :href="info.url"
                                        @click="toggle(info)"
                                    />
                                </div>
                            </div>
                            <a
                                v-if="
                                    length(grouped_results[group]) > expand_size
                                "
                                class="card-footer text-center clickable"
                                @click.stop.prevent="toggle_expanded(group)"
                            >
                                {{
                                    is_expanded(group)
                                        ? translations.actions.HIDE
                                        : translations.actions.SHOW_ALL +
                                          " (" +
                                          length(grouped_results[group]) +
                                          ")"
                                }}
                            </a>
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import barcode_regex from "constants/barcode"
import autocomplete from "js/constants/autocomplete"
import close from "js/mixins/close"
import search from "js/mixins/search"
import change_window_location from "js/navigation/change_window_location"
import state from "js/state/main"
import user from "js/state/user"
import cleanup from "js/utils/barcode_cleanup"
import get_django_url from "js/utils/get_django_url"
import is_firefox from "js/utils/is_firefox"
import contains from "rfuncs/functions/contains"
import filter from "rfuncs/functions/filter"
import length from "rfuncs/functions/length"
import map from "rfuncs/functions/map"
import merge from "rfuncs/functions/merge"
import object_map from "rfuncs/functions/object_map"
import ordered_object from "rfuncs/functions/ordered_object"
import scan from "rfuncs/functions/scan"
import sort_by from "rfuncs/functions/sort_by"
import make_translations from "workflow/utils/make_translations"
import proxy from "workflow/utils/proxy"
import { url_query_encode } from "workflow/utils/urlutilities"
import set_property from "workflow/vue/3/set_property"
export default {
    mixins: [close, search],
    props: ["query", "send"],

    data() {
        return {
            toggled_class: "search-body",

            tag: null,
            default_results: [],
            expanded: {},
            selected: null,
            expand_size: 10
        }
    },

    watch: {
        send(v) {
            if (this.is_barcode) {
                const qs = url_query_encode({ barcode: this.barcode_string })

                get_django_url("admin_barcode").then(url =>
                    change_window_location(url + "?" + qs)
                )

                this.close()
            }
        }
    },

    methods: {
        length,
        contains,
        is_firefox,
        toggle(info) {
            state.track_event("search_link", "search")
            change_window_location(info.url)
            this.close()
        },

        searchtab(selected) {
            this.selected = selected

            state.track_event("search_tab", "search")
        },
        is_expanded(pk) {
            return this.expanded[pk]
        },
        toggle_expanded(pk) {
            state.track_event("search_expand", "search")

            set_property(this.expanded, pk, !this.expanded[pk])
        },

        filter_results(results) {
            if (this.tag) {
                return filter(r => r.tags[this.tag] && r.in_search, results)
            }
            return object_map(
                o => o.pk,
                o => o,
                sort_by(
                    filter(r => r.in_search, results),
                    [r => r.kind.sequence, r => r.score]
                )
            )
        }
    },

    computed: merge(
        proxy(autocomplete, {
            pages_index: "all_options",
            tags_index: "tags",
            loading: "loading"
        }),

        {
            translations: () => make_translations("actions"),

            grouped_results() {
                let results = ordered_object()

                scan(
                    r =>
                        scan(t => {
                            if (!results[t.pk]) {
                                results[t.pk] = [r]
                            } else {
                                results[t.pk].push(r)
                            }
                        }, r.tags),
                    this.results
                )

                return results
            },
            visible_results() {
                return map(
                    (inner, group) =>
                        this.is_expanded(group)
                            ? inner
                            : filter((e, i) => i < this.expand_size, inner),
                    filter(
                        (inner, group) =>
                            !this.selected || this.selected == group,
                        this.grouped_results
                    )
                )
            },
            is_barcode() {
                if (this.can_use_barcode && this.query) {
                    const bits = filter(
                        s => barcode_regex.barcode.test(s),
                        this.query.split(barcode_regex.divider)
                    )
                    return length(bits) > 0
                }
                return false
            },
            can_use_barcode() {
                return user.is_staff
            },
            barcode_string() {
                return cleanup(this.query)
            }
        }
    )
}
</script>
