// prettier-ignore
export default {
    "DOWNLOAD": "T\u00e9l\u00e9charger",
    "CONTINUE": "Continuer",
    "LOADING": "Chargement",
    "SAVE": "Enregistrer",
    "ABORT": "Annuler",
    "NO_RESULTS": "Aucun r\u00e9sultat trouv\u00e9",
    "SHOW_ALL": "Tout afficher",
    "SEARCH": "Chercher",
    "HIDE": "Masquer",
    "ALL": "Tout",
    "CLOSE": "Fermer",
    "ADD": "Ajouter",
    "DELETE": "Supprimer",
    "CHANGE": "Modification",
    "COPY": "Copier",
    "GUEST": "Guest",
    "OPEN": "Ouvrir",
    "LOGIN": "Vous vous \u00eates enregistr\u00e9 avec succ\u00e8s en tant que ${user}.",
    "DROP_FILE": "Glissez un fichier ici",
    "DROP_FILES": "Glissez des fichiers ici",
    "DROP_OR_CLICK": "(ou cliquer pour rechercher)",
    "ARE_YOU_SURE": "\u00cates-vous s\u00fbr\u00a0?",
    "SERVER_ERROR_LONG": "Une erreur est survenue. Elle a \u00e9t\u00e9 transmise par courriel aux administrateurs du site et sera corrig\u00e9e dans les meilleurs d\u00e9lais. Merci pour votre patience.",
    "SEND": "Envoyer"
}