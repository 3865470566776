import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "content flex-grow-1 main-menu" }
const _hoisted_2 = { class: "container position-relative d-flex flex-column h-100" }
const _hoisted_3 = {
  key: 1,
  class: "w-100"
}
const _hoisted_4 = ["href", "onClick", "innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "w-100 h-100 text-center"
}
const _hoisted_7 = {
  key: 1,
  class: "row"
}
const _hoisted_8 = { class: "megamenu-section row-space markdown-compact" }
const _hoisted_9 = ["href", "title"]
const _hoisted_10 = ["href", "title"]
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 1,
  class: "d-flex flex-column"
}
const _hoisted_13 = ["href", "title", "onMouseout", "onMouseover"]
const _hoisted_14 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btn-close text-reset position-absolute end-0 me-3",
        type: "button",
        onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.close && _ctx.close(...args)))
      }),
      (!_ctx.is_main)
        ? (_openBlock(), _createElementBlock("a", {
            key: 0,
            class: "d-md-none clickable h5 dropdown-item d-block mt-0",
            onClick: _cache[1] || (_cache[1] = _withModifiers($event => ($options.open_link()), ["stop","prevent"]))
          }, "← " + _toDisplayString(_ctx.translations.actions.SHOW_ALL), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      (_ctx.is_main)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.megamenu_content, (el) => {
              return (_openBlock(), _createElementBlock("a", {
                class: "onhover text-nowrap dropdown-item h5",
                href: el.url,
                onClick: _withModifiers($event => ($options.open_link(el.url)), ["stop","prevent"]),
                innerHTML: el.title
              }, null, 8 /* PROPS */, _hoisted_4))
            }), 256 /* UNKEYED_FRAGMENT */)),
            (_ctx.html_content)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "w-100 d-flex flex-column",
                  innerHTML: _ctx.html_content
                }, null, 8 /* PROPS */, _hoisted_5))
              : _createCommentVNode("v-if", true)
          ]))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (!_ctx.main_descendants)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _cache[5] || (_cache[5] = [
                  _createElementVNode("div", { class: "spinner-border muted m-5 p-5" }, null, -1 /* HOISTED */)
                ])))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.main_descendants, (page) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: page.pk,
                      class: _normalizeClass(["col col-6", 
                            $options.length(_ctx.main_descendants) % 3 == 0 &&
                            $options.length(_ctx.main_descendants) <= 6 &&
                            $options.length(_ctx.main_descendants) % 4 != 0
                                ? 'col-lg-4'
                                : 'col-lg-3'
                        ]),
                      style: _normalizeStyle({ order: page.kind_sequence })
                    }, [
                      _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("a", {
                          class: "megamenu-preview rounded",
                          "data-track-event": "menu_link",
                          "data-track-type": "menu",
                          href: page.url,
                          style: _normalizeStyle({
                                    'background-image':
                                        'url(' +
                                        ($data.selected[page.pk]
                                            ? $data.selected[page.pk].img
                                            : page.img) +
                                        ')'
                                }),
                          title: page.unicode,
                          onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.close && _ctx.close(...args)))
                        }, null, 12 /* STYLE, PROPS */, _hoisted_9),
                        _createElementVNode("a", {
                          class: "h6",
                          "data-track-event": "menu_link",
                          "data-track-type": "menu",
                          href: page.url,
                          title: page.unicode,
                          onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.close && _ctx.close(...args)))
                        }, _toDisplayString(page.unicode), 9 /* TEXT, PROPS */, _hoisted_10),
                        ($options.length(page.descendants) == 0)
                          ? (_openBlock(), _createElementBlock("p", {
                              key: 0,
                              class: "small markdown",
                              innerHTML: page.desc
                            }, null, 8 /* PROPS */, _hoisted_11))
                          : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.truncate_links(page), (sub) => {
                                return (_openBlock(), _createElementBlock("a", {
                                  key: sub.pk,
                                  class: "megamenu-link",
                                  "data-track-event": "menu_link",
                                  "data-track-type": "menu",
                                  href: sub.url,
                                  style: _normalizeStyle({ order: sub.kind_sequence }),
                                  title: sub.unicode,
                                  onClick: _cache[4] || (_cache[4] = (...args) => (_ctx.close && _ctx.close(...args))),
                                  onMouseout: 
                                        () => $options.delete_property($data.selected, page.pk)
                                    ,
                                  onMouseover: 
                                        () =>
                                            $options.set_property($data.selected, page.pk, sub)
                                    
                                }, _toDisplayString(sub.unicode), 45 /* TEXT, STYLE, PROPS, NEED_HYDRATION */, _hoisted_13))
                              }), 128 /* KEYED_FRAGMENT */))
                            ])),
                        ($options.length(page.descendants) > $data.expand_size)
                          ? (_openBlock(), _createElementBlock("a", {
                              key: 2,
                              class: "megamenu-expand",
                              href: "#",
                              onClick: _withModifiers($event => ($options.toggle_expanded(page.pk)), ["prevent"])
                            }, _toDisplayString($options.is_expanded(page.pk)
                                        ? _ctx.translations.actions.HIDE
                                        : _ctx.translations.actions.SHOW_ALL +
                                          " (" +
                                          $options.length(page.descendants) +
                                          ")"), 9 /* TEXT, PROPS */, _hoisted_14))
                          : _createCommentVNode("v-if", true)
                      ])
                    ], 6 /* CLASS, STYLE */))
                  }), 128 /* KEYED_FRAGMENT */))
                ]))
          ], 64 /* STABLE_FRAGMENT */))
    ])
  ]))
}