<template>
    <div class="content flex-grow-1 main-menu">
        <div class="container position-relative d-flex flex-column h-100">
            <button
                class="btn-close text-reset position-absolute end-0 me-3"
                type="button"
                @click="close"
            />

            <a
                v-if="!is_main"
                class="d-md-none clickable h5 dropdown-item d-block mt-0"
                @click.stop.prevent="open_link()"
                >&larr; {{ translations.actions.SHOW_ALL }}</a
            >

            <div v-if="is_main" class="w-100">
                <a
                    v-for="el in megamenu_content"
                    class="onhover text-nowrap dropdown-item h5"
                    :href="el.url"
                    @click.stop.prevent="open_link(el.url)"
                    v-html="el.title"
                />

                <div
                    v-if="html_content"
                    class="w-100 d-flex flex-column"
                    v-html="html_content"
                />
            </div>
            <template v-else>
                <div v-if="!main_descendants" class="w-100 h-100 text-center">
                    <div class="spinner-border muted m-5 p-5" />
                </div>
                <div v-else class="row">
                    <div
                        v-for="page in main_descendants"
                        :key="page.pk"
                        class="col col-6"
                        :class="
                            length(main_descendants) % 3 == 0 &&
                            length(main_descendants) <= 6 &&
                            length(main_descendants) % 4 != 0
                                ? 'col-lg-4'
                                : 'col-lg-3'
                        "
                        :style="{ order: page.kind_sequence }"
                    >
                        <div
                            class="megamenu-section row-space markdown-compact"
                        >
                            <a
                                class="megamenu-preview rounded"
                                data-track-event="menu_link"
                                data-track-type="menu"
                                :href="page.url"
                                :style="{
                                    'background-image':
                                        'url(' +
                                        (selected[page.pk]
                                            ? selected[page.pk].img
                                            : page.img) +
                                        ')'
                                }"
                                :title="page.unicode"
                                @click="close"
                            >
                            </a>
                            <a
                                class="h6"
                                data-track-event="menu_link"
                                data-track-type="menu"
                                :href="page.url"
                                :title="page.unicode"
                                @click="close"
                                >{{ page.unicode }}</a
                            >
                            <p
                                v-if="length(page.descendants) == 0"
                                class="small markdown"
                                v-html="page.desc"
                            ></p>
                            <div v-else class="d-flex flex-column">
                                <a
                                    v-for="sub in truncate_links(page)"
                                    :key="sub.pk"
                                    class="megamenu-link"
                                    data-track-event="menu_link"
                                    data-track-type="menu"
                                    :href="sub.url"
                                    :style="{ order: sub.kind_sequence }"
                                    :title="sub.unicode"
                                    @click="close"
                                    @mouseout="
                                        () => delete_property(selected, page.pk)
                                    "
                                    @mouseover="
                                        () =>
                                            set_property(selected, page.pk, sub)
                                    "
                                    >{{ sub.unicode }}</a
                                >
                            </div>
                            <a
                                v-if="length(page.descendants) > expand_size"
                                class="megamenu-expand"
                                href="#"
                                @click.prevent="toggle_expanded(page.pk)"
                            >
                                {{
                                    is_expanded(page.pk)
                                        ? translations.actions.HIDE
                                        : translations.actions.SHOW_ALL +
                                          " (" +
                                          length(page.descendants) +
                                          ")"
                                }}
                            </a>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import autocomplete from "js/constants/autocomplete"
import selector from "js/dom/selector"
import close from "js/mixins/close"
import navigate from "js/routes/navigate"
import { MENU_VIEW } from "js/routes/urls"
import state from "js/state/main"
import filter from "rfuncs/functions/filter"
import length from "rfuncs/functions/length"
import map from "rfuncs/functions/map"
import merge from "rfuncs/functions/merge"
import make_translations from "workflow/utils/make_translations"
import proxy from "workflow/utils/proxy"
import delete_property from "workflow/vue/3/delete_property"
import set_property from "workflow/vue/3/set_property"

export default {
    mixins: [close],
    props: ["url", "title"],
    data() {
        return {
            toggled_class: "search-body",
            expand_size: 6,
            expanded: {},
            selected: {},
            html_selector: ".main-offcanvas",
            megamenu_selector: ".main-megamenu[href]"
        }
    },
    methods: {
        length,
        set_property,
        delete_property,

        open_link(url) {
            navigate(MENU_VIEW, { url })
        },
        descendants(url) {
            if (url && this.pages_descendants_by_url) {
                return filter(
                    p => p.in_navigation,
                    this.pages_descendants_by_url[url] || []
                )
            }
            return []
        },
        is_expanded(pk) {
            return this.expanded[pk]
        },
        toggle_expanded(pk) {
            set_property(this.expanded, pk, !this.expanded[pk])

            if (this.expanded[pk]) {
                state.track_event("menu_expand", "menu")
            }
        },
        truncate_links(page) {
            if (this.is_expanded(page.pk)) {
                return page.descendants
            }

            return filter(
                (page, index) => index < this.expand_size,
                page.descendants
            )
        }
    },

    computed: merge(
        proxy(autocomplete, [
            "pages_index",
            "pages_descendants_by_url",
            "pages_by_url"
        ]),
        {
            translations: () => make_translations("actions"),
            main_descendants() {
                if (
                    this.url &&
                    this.pages_by_url &&
                    this.pages_by_url[this.url]
                ) {
                    let array = this.descendants(this.url)
                    if (array && this.limit) {
                        array = array.slice(0, this.limit)
                    }
                    return map(
                        o =>
                            merge(o, {
                                descendants: this.descendants(o.url)
                            }),
                        array
                    )
                }
            },
            is_main() {
                return this.url ? false : true
            },
            html_content() {
                return map(e => e.innerHTML, selector(this.html_selector)).join(
                    ""
                )
            },
            megamenu_content() {
                return map(
                    e => ({ title: e.innerText, url: e.getAttribute("href") }),
                    selector(this.megamenu_selector)
                )
            }
        }
    )
}
</script>

<style lang="css">
.main-menu .dropdown-header,
.main-menu .nav-discount {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid #ddd;
}
.main-menu .dropdown-item {
    padding-top: 0.25rem;
    margin-top: 0.25rem;
}
</style>
