import selector from "js/dom/selector"
import map from "rfuncs/functions/map"
import scan from "rfuncs/functions/scan"

export default (el, classes) => {
    return map(e => {
        scan(
            (check, clist) =>
                scan(
                    c => e.classList[check ? "add" : "remove"](c),

                    clist ? clist.split(" ") : []
                ),

            classes
        )
        return e
    }, selector(el))
}
