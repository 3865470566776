import download_file from "js/files/actions/download_file"
import wait_files from "js/files/actions/wait_files"
import new_zip_from_files from "js/files/make/new_zip_from_files"
import first from "rfuncs/functions/first"
import length from "rfuncs/functions/length"
import to_array from "rfuncs/functions/to_array"

export default async (files, force_download = true) => {
    const { url, name } = first(
        await wait_files(
            length(files) > 1
                ? [new_zip_from_files(to_array(files))]
                : to_array(files)
        )
    )

    return download_file(url, force_download ? name : null)
}
