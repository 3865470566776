<template>
    <div
        class="modal fade show opacity-100 modal p-3 bg-glass-dark d-flex align-items-center justify-content-center"
        @click="close"
    >
        <div
            class="modal-dialog modal-fullscreen bg-white rounded"
            style="height: calc(100vh - 50px); width: calc(100vw - 50px)"
            @click.stop
        >
            <div class="modal-content position-relative rounded">
                <div class="carousel carousel-dark slide h-100 rounded">
                    <div class="carousel-inner w-100 h-100 rounded">
                        <img
                            class="carousel-item active img-cover w-100 h-100 rounded"
                            :src="current.src"
                            :title="current.title"
                        />
                    </div>

                    <div class="carousel-indicators">
                        <button
                            v-for="(image, index) in images"
                            :class="{ active: image.src == current.src }"
                            data-bs-target
                            :title="image.title"
                            type="button"
                            @click="goto(index)"
                        ></button>
                    </div>

                    <button
                        class="carousel-control-prev"
                        type="button"
                        @click.prevent="prev"
                    >
                        <span class="carousel-control-prev-icon"></span>
                    </button>
                    <button
                        class="carousel-control-next"
                        type="button"
                        @click.prevent="next"
                    >
                        <span class="carousel-control-next-icon"></span>
                    </button>
                </div>

                <button
                    class="btn-close position-absolute top-0 end-0 mx-4 my-4 p-2 rounded-circle border bg-light"
                    style="z-index: 100000"
                    :title="translations.actions.CLOSE"
                    type="button"
                    @click="close"
                />
            </div>
        </div>
    </div>
</template>

<script>
import selector from "js/dom/selector"
import close from "js/mixins/close"
import navigate from "js/routes/navigate"
import { SLIDESHOW_VIEW } from "js/routes/urls"
import keyboard from "js/state/keyboard"
import filter from "rfuncs/functions/filter"
import first from "rfuncs/functions/first"
import length from "rfuncs/functions/length"
import map from "rfuncs/functions/map"
import merge from "rfuncs/functions/merge"
import make_translations from "workflow/utils/make_translations"
import proxy from "workflow/utils/proxy"
export default {
    mixins: [close],
    props: ["selector", "selected"],
    data() {
        return {
            toggled_class: "search-body"
        }
    },
    watch: {
        up_pressed(v) {
            if (v) this.prev()
        },
        down_pressed(v) {
            if (v) this.next()
        },
        left_pressed(v) {
            if (v) this.prev()
        },
        right_pressed(v) {
            if (v) this.next()
        }
    },
    methods: {
        goto(position) {
            let next = position

            if (next + 1 > length(this.images)) next = 0
            if (next < 0) next = length(this.images) - 1

            navigate(SLIDESHOW_VIEW, {
                selector: this.selector,
                selected: this.images[next].src
            })
        },
        next() {
            this.goto(this.current_index + 1)
        },
        prev() {
            this.goto(this.current_index - 1)
        }
    },

    computed: merge(
        proxy(keyboard, {
            ARROW_LEFT: "left_pressed",
            ARROW_UP: "up_pressed",
            ARROW_DOWN: "down_pressed",
            ARROW_RIGHT: "right_pressed"
        }),

        {
            translations: () => make_translations(["actions"]),
            images() {
                return this.selector
                    ? map(
                          e => ({
                              title: e.title,
                              description: e.alt,
                              src: e.getAttribute("data-slide-src") || e.src
                          }),
                          selector(this.selector)
                      )
                    : []
            },
            current() {
                return (
                    first(filter(e => e.src == this.selected, this.images)) ||
                    first(this.images)
                )
            },
            current_index() {
                return this.images.indexOf(this.current)
            }
        }
    )
}
</script>
