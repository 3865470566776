<template>
    <input
        v-model="barcode"
        autocomplete="off"
        class="form-control"
        :name="name"
        :placeholder="placeholder"
        type="text"
    />
</template>

<script>
import cleanup from "js/utils/barcode_cleanup"

export default {
    data() {
        return { barcode: cleanup(this.value) }
    },
    props: ["name", "value", "placeholder"],
    methods: {
        cleanup() {
            this.barcode = cleanup(this.barcode)
        }
    },
    watch: {
        barcode() {
            this.cleanup()
        }
    }
}
</script>
