// prettier-ignore
export default {
    "DOWNLOAD": "Download",
    "CONTINUE": "Continue",
    "LOADING": "Loading",
    "SAVE": "Save",
    "ABORT": "Abort",
    "NO_RESULTS": "No results found",
    "SHOW_ALL": "Show all",
    "SEARCH": "Search",
    "HIDE": "Hide",
    "ALL": "All",
    "CLOSE": "Close",
    "ADD": "Add",
    "DELETE": "Delete",
    "CHANGE": "Change",
    "COPY": "Copy",
    "GUEST": "Guest",
    "OPEN": "Open",
    "LOGIN": "You succesfully logged in as ${user}.",
    "DROP_FILE": "Drag and drop a file here",
    "DROP_FILES": "Drag and drop files here",
    "DROP_OR_CLICK": "(or click to browse)",
    "ARE_YOU_SURE": "Are you sure?",
    "SERVER_ERROR_LONG": "There\u2019s been an error. It\u2019s been reported to the site administrators via email and should be fixed shortly. Thanks for your patience.",
    "SEND": "Send"
}