import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["value", "innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($options.objects)
    ? (_openBlock(), _createElementBlock("datalist", {
        key: 0,
        id: $props.id
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.sizes, ([name, size]) => {
          return (_openBlock(), _createElementBlock("option", {
            value: size,
            innerHTML: name
          }, null, 8 /* PROPS */, _hoisted_2))
        }), 256 /* UNKEYED_FRAGMENT */))
      ], 8 /* PROPS */, _hoisted_1))
    : _createCommentVNode("v-if", true)
}