import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["name", "placeholder"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("input", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.barcode) = $event)),
    autocomplete: "off",
    class: "form-control",
    name: $props.name,
    placeholder: $props.placeholder,
    type: "text"
  }, null, 8 /* PROPS */, _hoisted_1)), [
    [_vModelText, $data.barcode]
  ])
}