import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center border-0 p-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.operation_perc > 0)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createTextVNode(_toDisplayString(parseInt($props.operation_perc * 100)) + "% ", 1 /* TEXT */)
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true),
    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "mx-1 spinner-border spinner-border-sm" }, null, -1 /* HOISTED */))
  ]))
}