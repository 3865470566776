<template>
    <label
        class="btn btn-outline-light text-start w-100 pe-2"
        :class="{
            'bg-white': length(files) + length(available_files),
            'ps-2': length(files)
        }"
        :for="id"
        @drop.prevent.stop="create_file"
    >
        <div class="btn-toolbar">
            <div
                v-for="file in sorted_files"
                :key="file.id"
                class="input-group input-group-sm me-2"
            >
                <a
                    class="btn p-0 px-1"
                    :class="
                        guess_filecheck(file.short_name)
                            ? 'btn-success text-white'
                            : file.invalid
                              ? 'btn-danger  text-white'
                              : 'btn-light'
                    "
                    :download="file.short_name"
                    :href="file.url"
                    target="_blank"
                    :title="file.short_name"
                    @click.prevent.stop="open_file(file)"
                >
                    {{ truncate_chars(file.short_name) }}
                </a>
                <input
                    v-if="file.remote_name"
                    :name="field"
                    type="hidden"
                    :value="file.remote_name"
                />
                <a
                    class="btn p-0 px-1"
                    :class="
                        guess_filecheck(file.short_name)
                            ? 'btn-success text-white'
                            : file.invalid
                              ? 'btn-danger  text-white'
                              : 'btn-light'
                    "
                    :disabled="upload_task"
                    :title="translations.actions.DELETE"
                    @click.prevent.stop="delete_file(file.id)"
                >
                    &times;
                </a>
            </div>

            <div v-if="length(files) == 0">
                <i class="glyphicon glyphicon-file me-1"></i>
                <span v-if="multiple">{{
                    translations.actions.DROP_FILES
                }}</span>
                <span v-else>{{ translations.actions.DROP_FILE }}</span>
                <span
                    v-if="!is_staff"
                    class="small fst-italic ms-1 text-muted"
                    >{{ translations.actions.DROP_OR_CLICK }}</span
                >
            </div>
            <task_progress v-if="is_loading" />

            <div
                v-else-if="length(available_files) > 0"
                class="input-group input-group-sm ms-auto"
            >
                <div
                    class="btn btn-sm btn-outline-light p-0 px-1"
                    @click.prevent.stop="add_available_files"
                >
                    {{ translations.actions.ADD }}
                    {{ `${length(available_files)} file` }}
                </div>
                <div
                    class="btn btn-sm btn-outline-light p-0 px-1"
                    :title="translations.actions.DELETE"
                    @click.prevent.stop="delete_available_files"
                >
                    ×
                </div>
            </div>

            <div
                v-else-if="length(files) > 0"
                class="btn btn-outline-light p-0 px-1 border-0 ms-auto"
                :title="translations.actions.DELETE"
                @click.prevent.stop="delete_file()"
            >
                &times;
            </div>
        </div>

        <input
            v-show="false"
            :id="id"
            ref="file"
            :accept="accept"
            :multiple="multiple ? true : false"
            type="file"
            @change.prevent="create_file"
        />
    </label>
</template>

<script>
import task_progress from "js/comp/task_progress"
import closest from "js/dom/closest"
import fire_event from "js/dom/fire_event"
import download_files from "js/files/actions/download_files"
import upload_files from "js/files/actions/upload_files"
import wait_files from "js/files/actions/wait_files"
import new_files_from_datatransfer from "js/files/make/new_files_from_datatransfer"
import guess_extension from "js/files/naming/guess_extension"
import guess_filecheck from "js/files/naming/guess_filecheck"
import filenames from "js/mixins/filenames"
import storage from "js/state/storage"
import user from "js/state/user"
import any from "rfuncs/functions/any"
import contains from "rfuncs/functions/contains"
import filter from "rfuncs/functions/filter"
import keys from "rfuncs/functions/keys"
import length from "rfuncs/functions/length"
import merge from "rfuncs/functions/merge"
import object_map from "rfuncs/functions/object_map"
import scan from "rfuncs/functions/scan"
import sort_by from "rfuncs/functions/sort_by"
import values from "rfuncs/functions/values"
import make_translations from "workflow/utils/make_translations"
import proxy from "workflow/utils/proxy"
import truncate_chars from "workflow/utils/truncate_chars"
import uuid from "workflow/utils/uuid"
import delete_property from "workflow/vue/3/delete_property"

export default {
    mixins: [filenames],
    props: ["field", "clear", "accept", "multiple", "noglobals"],
    emits: ["file-add", "file-change", "file-remove", "file-upload"],
    components: { task_progress },
    data() {
        return {
            upload_task: null,
            files: {},
            id: uuid()
        }
    },
    mounted() {
        this.files = object_map(
            f => f.id,
            f => f,
            this.filenames
        )

        this.$nextTick(() => {
            if (this.field) {
                const form = closest(this.$el, "form")
                if (form) {
                    form.addEventListener("submit", event => {
                        if (length(this.local_files) > 0) {
                            event.preventDefault()
                            event.stopPropagation()
                            this.upload_files().then(() =>
                                fire_event(form, "submit")
                            )
                        }
                    })
                }
            }
        })
    },
    computed: merge(
        proxy(storage, { selected_files: "global_files" }),
        proxy(user, ["is_staff"]),

        {
            translations: () => make_translations("actions"),
            local_files() {
                return filter(f => !f.remote_name, this.files)
            },
            available_files() {
                return this.noglobals
                    ? []
                    : filter(
                          f => !contains(this.files, f.id),
                          this.global_files
                      )
            },
            sorted_files() {
                return sort_by(this.files, "name")
            },
            is_loading() {
                return any(this.files, f => f.loading)
            }
        }
    ),
    methods: {
        length,
        guess_extension,
        guess_filecheck,
        truncate_chars,
        open_file(file) {
            download_files([file], false)
        },

        delete_file(id) {
            if (id) {
                const f = this.files[id]
                if (f) {
                    delete_property(this.files, id)
                    this.$emit("file-remove", f)

                    if (this.is_staff && !this.noglobals) storage.add_files([f])
                }
            } else {
                scan(this.delete_file, keys(this.files))
            }
        },
        delete_available_files() {
            storage.unselect_files(keys(this.available_files))
        },
        push_file(file) {
            if (this.files[file.id]) {
                this.$emit("file-change", file)
            } else {
                this.$emit("file-add", file)
            }
            this.files[file.id] = file
        },
        create_file(e) {
            // inject the form handler

            if (!this.multiple) this.files = {}

            scan(
                file => this.push_file(file),
                new_files_from_datatransfer(
                    e.dataTransfer ? e.dataTransfer.files : e.target.files
                )
            )

            if (e.target) {
                e.target.value = null
            }
        },
        add_available_files() {
            scan(f => this.push_file(f), this.available_files)
        },
        upload_files() {
            return wait_files(values(this.local_files))
                .then(upload_files)
                .then(wait_files)
                .then(files => this.$emit("file-upload", files))
        }
    }
}
</script>

<style lang="scss" scoped></style>
