import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-collapsable" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "text",
      class: _normalizeClass(["text-content", { 'text-collapsed': $options.is_collapsed && $data.needs_collapse }]),
      style: _normalizeStyle({ 'max-height': $options.is_collapsed ? $data.max_height + 'px' : null }),
      innerHTML: $props.content
    }, null, 14 /* CLASS, STYLE, PROPS */, _hoisted_2),
    ($data.needs_collapse && !$props.selector)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass($props.link ? 'text-start pt-0' : 'text-center pt-3')
        }, [
          _createElementVNode("a", {
            class: _normalizeClass($props.link ? 'text-muted small' : 'btn btn-outline-light'),
            href: "#",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => ($data.collapsed = !$data.collapsed), ["prevent","stop"]))
          }, _toDisplayString($data.collapsed
                        ? $options.translations.actions.SHOW_ALL
                        : $options.translations.actions.HIDE) + " → ", 3 /* TEXT, CLASS */)
        ], 2 /* CLASS */))
      : _createCommentVNode("v-if", true)
  ]))
}