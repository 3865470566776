import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withModifiers as _withModifiers, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["placeholder"]
const _hoisted_2 = ["name", "value"]
const _hoisted_3 = {
  key: 0,
  class: "autocomplete-menu no-scrollbar"
}
const _hoisted_4 = { class: "dropdown-list dropdown-menu d-flex flex-column" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_7 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "input-autocomplete",
    onClick: _cache[9] || (_cache[9] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass({ 'input-group': !_ctx.hide_dropdown })
    }, [
      _withDirectives(_createElementVNode("input", {
        ref: "input",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.query) = $event)),
        class: "form-control",
        placeholder: $options.input_placeholder,
        onBlur: _cache[1] || (_cache[1] = 
                    () => {
                        $data.open = false
                        $data.query = ''
                    }
                ),
        onClick: _cache[2] || (_cache[2] = 
                    () => {
                        $data.open = true
                        $data.show_all = false
                    }
                ),
        onKeydown: _cache[3] || (_cache[3] = (...args) => ($options.keydown && $options.keydown(...args)))
      }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1), [
        [_vModelText, $data.query]
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.selection, (info, pk) => {
        return (_openBlock(), _createElementBlock("input", {
          ref_for: true,
          ref: "hidden",
          name: $props.name,
          type: "hidden",
          value: pk
        }, null, 8 /* PROPS */, _hoisted_2))
      }), 256 /* UNKEYED_FRAGMENT */)),
      _withDirectives(_createElementVNode("select", {
        ref: "hidden",
        class: "form-select flex-grow-0",
        onClick: _cache[4] || (_cache[4] = _withModifiers(
                    () => {
                        $data.open = !$data.open || !$data.show_all
                        $data.query = ''
                        $data.show_all = true
                    }
                , ["stop","prevent"])),
        onMousedown: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop","prevent"])),
        onMouseup: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop","prevent"]))
      }, null, 544 /* NEED_HYDRATION, NEED_PATCH */), [
        [_vShow, !_ctx.hide_dropdown]
      ])
    ], 2 /* CLASS */),
    ($data.open && $options.length(_ctx.results))
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (info, i) => {
              return (_openBlock(), _createElementBlock("li", {
                key: i,
                class: _normalizeClass(["dropdown-item onhover", {
                        selected: $options.contains($data.selection, info.pk),
                        active: info.pk == $options.active_key
                    }]),
                style: _normalizeStyle({ order: info.score || 0 }),
                onClick: _withModifiers($event => ($options.toggle(info)), ["stop","prevent"]),
                onMousedown: _cache[7] || (_cache[7] = _withModifiers(() => {}, ["stop","prevent"])),
                onMouseup: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["stop","prevent"]))
              }, [
                _createElementVNode("a", _hoisted_6, [
                  _createElementVNode("span", {
                    innerHTML: info.unicode
                  }, null, 8 /* PROPS */, _hoisted_7),
                  _createElementVNode("small", {
                    class: _normalizeClass({
                                'onhover-show-visibility': info.pk != $options.active_key
                            })
                  }, _toDisplayString(info.group), 3 /* TEXT, CLASS */)
                ])
              ], 46 /* CLASS, STYLE, PROPS, NEED_HYDRATION */, _hoisted_5))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}