import keys from "rfuncs/functions/keys"
import map from "rfuncs/functions/map"
import imposition_plot_target from "workflow/actions/imposition_plot_target"
import { get_imposition_dispatch_data } from "workflow/actions/imposition_plot_target"

export default async (
    { imposition_url, filenames, prefix, printable },
    ...args
) => {
    const data = await get_imposition_dispatch_data(imposition_url, ...args)

    return await Promise.all(
        map(
            target =>
                imposition_plot_target(
                    { imposition_url, filenames, prefix, target },
                    ...args
                ),
            keys(data.actions)
        )
    )
}
