import toggle_class from "js/dom/toggle_class"
import user from "js/state/user"
import persistence from "js/utils/persistence"
import serialize_error from "js/utils/serialize_error"
import scan from "rfuncs/functions/scan"
import virtual from "workflow/vue/3/virtual"

const store = persistence.make_encoder("cookielaw")

export default virtual({
    data: () => {
        return {
            IS_SUBMITTED: false,
            LAST_FOCUS: null,
            AJAX_REQUEST_CACHE: {},
            AJAX_COUNTER: 0,

            request_id: null,
            event_handlers: [],
            settings: {},
            translations: {},
            cookielaw: store.get()
        }
    },
    methods: {
        track_event(...args) {
            scan(func => func(...args), this.event_handlers)
        },
        track_error(error, error_type) {
            this.track_event(`${error}`, error_type)
            serialize_error(error)
        }
    },
    computed: {
        user() {
            return user
        }
    },
    watch: {
        cookielaw(value) {
            store.set(value)
            toggle_class(document.body, {
                "body-cookielaw": !value,
                "body-cookielaw-big": !value
            })
        }
    }
})
