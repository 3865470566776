import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder", "value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("form", {
    class: "input-group w-100",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.searchsubmit && $options.searchsubmit(...args)), ["stop","prevent"]))
  }, [
    _createElementVNode("input", {
      ref: "input",
      autocomplete: "off",
      class: "form-control",
      placeholder: $options.full_placeholder,
      type: "search",
      value: $options.query,
      onInput: _cache[0] || (_cache[0] = (...args) => ($options.searchinput && $options.searchinput(...args)))
    }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_1)
  ], 32 /* NEED_HYDRATION */))
}