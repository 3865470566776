// prettier-ignore
export default {
    "DOWNLOAD": "Scarica",
    "CONTINUE": "Continua",
    "LOADING": "Caricamento",
    "SAVE": "Salva",
    "ABORT": "Annulla",
    "NO_RESULTS": "Nessun risultato trovato",
    "SHOW_ALL": "Mostra tutto",
    "SEARCH": "Cerca",
    "HIDE": "Nascondi",
    "ALL": "Tutto",
    "CLOSE": "Chiudi",
    "ADD": "Aggiungi",
    "DELETE": "Cancella",
    "CHANGE": "Modifica",
    "COPY": "Copia",
    "GUEST": "Ospite",
    "OPEN": "Apri",
    "LOGIN": "Ti sei loggato con successo come ${user}.",
    "DROP_FILE": "Trascina un file qui",
    "DROP_FILES": "Trascina dei file qui",
    "DROP_OR_CLICK": "(o clicca per sfogliare)",
    "ARE_YOU_SURE": "Confermi?",
    "SERVER_ERROR_LONG": "Si \u00e8 verificato un errore. Gli amministratori del sito ne sono stati informati per email, e vi porranno rimedio a breve. Grazie per la vostra pazienza.",
    "SEND": "Invia"
}