import upload_files from "js/files/actions/upload_files"
import wait_files from "js/files/actions/wait_files"
import start_request from "js/navigation/start_request"
import get_django_url from "js/utils/get_django_url"
import length from "rfuncs/functions/length"
import map from "rfuncs/functions/map"
import values from "rfuncs/functions/values"
import { url_query_encode } from "workflow/utils/urlutilities"

export default (outfolder, files_dict, session = null) => {
    const files = values(files_dict)

    if (length(files) > 0)
        return wait_files(files)
            .then(upload_files)
            .then(wait_files)
            .then(files =>
                get_django_url("upload_outfolders").then(url =>
                    start_request(url, {
                        change_location: false,
                        scroll_control: false,
                        data: url_query_encode({
                            outfolder: outfolder,
                            session: session,
                            files: map(f => f.remote_name, files)
                        })
                    })
                )
            )
}
