// prettier-ignore
export default {
	"en": {
		"actions": () => import(/* webpackChunkName: "en-actions" */ "./languages/en/actions"),
		"filecheck": () => import(/* webpackChunkName: "en-filecheck" */ "./languages/en/filecheck"),
		"cookie": () => import(/* webpackChunkName: "en-cookie" */ "./languages/en/cookie"),
		"history_types": () => import(/* webpackChunkName: "en-history_types" */ "./languages/en/history_types"),
		"urls": () => import(/* webpackChunkName: "en-urls" */ "./languages/en/urls"),
		"trackers": () => import(/* webpackChunkName: "en-trackers" */ "./languages/en/trackers"),
		"errors": () => import(/* webpackChunkName: "en-errors" */ "./languages/en/errors"),
	},
	"fr": {
		"actions": () => import(/* webpackChunkName: "fr-actions" */ "./languages/fr/actions"),
		"filecheck": () => import(/* webpackChunkName: "fr-filecheck" */ "./languages/fr/filecheck"),
		"cookie": () => import(/* webpackChunkName: "fr-cookie" */ "./languages/fr/cookie"),
		"history_types": () => import(/* webpackChunkName: "fr-history_types" */ "./languages/fr/history_types"),
		"urls": () => import(/* webpackChunkName: "fr-urls" */ "./languages/fr/urls"),
		"trackers": () => import(/* webpackChunkName: "fr-trackers" */ "./languages/fr/trackers"),
		"errors": () => import(/* webpackChunkName: "fr-errors" */ "./languages/fr/errors"),
	},
	"it": {
		"actions": () => import(/* webpackChunkName: "it-actions" */ "./languages/it/actions"),
		"filecheck": () => import(/* webpackChunkName: "it-filecheck" */ "./languages/it/filecheck"),
		"cookie": () => import(/* webpackChunkName: "it-cookie" */ "./languages/it/cookie"),
		"history_types": () => import(/* webpackChunkName: "it-history_types" */ "./languages/it/history_types"),
		"urls": () => import(/* webpackChunkName: "it-urls" */ "./languages/it/urls"),
		"trackers": () => import(/* webpackChunkName: "it-trackers" */ "./languages/it/trackers"),
		"errors": () => import(/* webpackChunkName: "it-errors" */ "./languages/it/errors"),
	},
}