<template></template>

<script>
import ensure_unique_names from "js/files/actions/ensure_unique_names"
import new_file_from_remote_id from "js/files/make/new_file_from_remote_id"
import is_string from "rfuncs/functions/is_string"
import map from "rfuncs/functions/map"
import json from "workflow/utils/json"

export default {
    props: ["filelist"],
    computed: {
        filenames() {
            const filelist = is_string(this.filelist)
                ? json.loads(this.filelist || "[]")
                : this.filelist

            return ensure_unique_names(
                map((f, i) => new_file_from_remote_id(f), filelist)
            )
        }
    }
}
</script>
