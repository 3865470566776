import {
    Base64Decoder as Decoder,
    Base64Encoder as Encoder
} from "next-base64-encoder"
import composition from "rfuncs/functions/composition"

export const make_encoder = (Encoder, Decoder) => ({
    dumps: composition(
        v => new TextEncoder().encode(v),
        v => new Decoder().decode(v)
    ),
    loads: composition(
        v => new Encoder().encode(v),
        v => new TextDecoder().decode(v)
    )
})

export default make_encoder(Encoder, Decoder)
