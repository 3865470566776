import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("a", {
    ref: "link",
    class: "onhover text-nowrap main-megamenu clickable",
    href: $props.url || '/',
    tabindex: "-1",
    onBlur: _cache[0] || (_cache[0] = (...args) => (_ctx.close && _ctx.close(...args))),
    onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.menu_click && $options.menu_click(...args)), ["prevent","stop"])),
    onMouseover: _cache[2] || (_cache[2] = (...args) => ($options.menu_hover && $options.menu_hover(...args)))
  }, [
    _createElementVNode("span", { innerHTML: $props.content }, null, 8 /* PROPS */, _hoisted_2)
  ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_1))
}