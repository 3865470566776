<template></template>
<script>
import slugify from "js/utils/slugify"
import MiniSearch from "minisearch"
import first from "rfuncs/functions/first"
import identity from "rfuncs/functions/identity"
import keys from "rfuncs/functions/keys"
import length from "rfuncs/functions/length"
import map from "rfuncs/functions/map"
import merge from "rfuncs/functions/merge"
import object_map from "rfuncs/functions/object_map"
import values from "rfuncs/functions/values"

// abstract class needs to implement methods:
//
// filter_results
// default_results
//
// needs to implement props
//
// query

const create_search_index = (options, documents) => {
    const index = new MiniSearch(
        merge({ storeFields: keys(first(documents)) }, options)
    )

    index.addAll(documents)

    return index
}

export default {
    methods: {
        filter_results: identity
    },
    computed: {
        index() {
            if (length(this.all_options)) {
                return create_search_index(
                    {
                        fields: ["unicode", "slug", "keys"],
                        idField: "pk",
                        searchOptions: {
                            boost: { unicode: 10, slug: 7, keys: 4 },
                            fuzzy: 0.2,
                            prefix: true
                        }
                    },
                    map(
                        i => merge(i, { slug: slugify(i.unicode) }),
                        values(this.all_options)
                    )
                )
            }
            return null
        },
        results() {
            if (this.query && this.index)
                return object_map(
                    v => v.pk,
                    identity,
                    this.index.search(this.query)
                )
            return this.filter_results(this.default_results)
        }
    }
}
</script>
