import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "rating-widget" }
const _hoisted_2 = ["name", "value"]
const _hoisted_3 = ["onClick"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      name: $props.name,
      type: "hidden",
      value: $props.value
    }, null, 8 /* PROPS */, _hoisted_2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.range, (i) => {
      return (_openBlock(), _createElementBlock("i", {
        key: i,
        class: _normalizeClass(["glyphicon-star glyphicon review-star", { 'review-star-active': i <= $props.value }]),
        onClick: () => ($props.value = i)
      }, null, 10 /* CLASS, PROPS */, _hoisted_3))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}