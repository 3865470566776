import append from "js/dom/append"
import element from "js/dom/element"
import toggle_class from "js/dom/toggle_class"
import router from "js/routes/router"
import is_array from "rfuncs/functions/is_array"
import keys from "rfuncs/functions/keys"
import merge from "rfuncs/functions/merge"
import object_map from "rfuncs/functions/object_map"
import scan from "rfuncs/functions/scan"
import create_app from "workflow/vue/3/create_app"

export default function init(cls, el, data) {
    const mounted = create_app({
        el: element("div"),
        app: cls,
        router: router,
        props: merge(
            el
                ? object_map(
                      k => k,
                      k => el.getAttribute("data-" + k) || null,
                      is_array(cls.props) ? cls.props : keys(cls.props)
                  )
                : {},
            el
                ? {
                      disabled: el.hasAttribute("disabled") ? true : false,
                      value: el.value,
                      name: el.name,
                      placeholder: el.getAttribute("placeholder") || undefined,
                      content: el.innerHTML
                  }
                : {},
            data || {}
        )
    })

    if (el) {
        scan(
            attr => {
                const value = el.getAttribute(attr)
                if (value) mounted.$el.setAttribute(attr, value)
            },
            ["id", "style"]
        )

        if (data && data.preserve_class) {
            toggle_class(
                mounted.$el,
                object_map(
                    e => e,
                    e => true,
                    el.classList
                )
            )
        }

        el.replaceWith(mounted.$el)
    } else {
        append(document.body, mounted.$el)
    }
    return mounted
}
