
.main-menu .dropdown-header,
.main-menu .nav-discount {
    padding-top: 1rem;
    margin-top: 1rem;
    border-top: 1px solid #ddd;
}
.main-menu .dropdown-item {
    padding-top: 0.25rem;
    margin-top: 0.25rem;
}
