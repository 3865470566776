import element from "js/dom/element"

export default async (url, name) => {
    // make and click a temporary link to download the Blob
    const link = element("a")
    link.href = url
    if (name) {
        link.download = name
    }
    link.target = "_blank"
    link.click()
    link.remove()
}
