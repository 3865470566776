<template></template>

<script>
import messages from "js/state/messages"
import { BROWSER_ERROR } from "translations/enum/errors"

export default {
    methods: {
        initial_action: () => messages.send_error(BROWSER_ERROR)
    },
    mounted() {
        this.initial_action()
    }
}
</script>
