import { submit_prepress_files } from "js/comp/files/actions/prepress_action"
import settings_get from "js/constants/settings_get"
import upload_files from "js/files/actions/upload_files"
import wait_files from "js/files/actions/wait_files"
import new_file from "js/files/make/new_file"
import start_request from "js/navigation/start_request"
import keys from "rfuncs/functions/keys"
import map from "rfuncs/functions/map"
import object_map from "rfuncs/functions/object_map"
import values from "rfuncs/functions/values"
import { get_imposition_dispatch_data } from "workflow/actions/imposition_plot_target"
import add_b64_to_url from "workflow/tasks/plot/utils/add_b64_to_url"
import { url_query_encode } from "workflow/utils/urlutilities"

export const create_download_action = () => ["download"]
export const create_prepress_action = () => ["prepress"]
export const create_change_action = url => ["change", url]

const actions = {
    download: async () => {},
    prepress: async ({ imposition_files }) =>
        submit_prepress_files(await imposition_files("single_pdf")),
    change: async (s, url) => {
        const existing = object_map(
            f => f.short_name,
            f => f,
            s.existing
        )

        const { actions } = await get_imposition_dispatch_data(
            s.imposition_url("on", "all")
        )

        const files = await wait_files(
            values(
                map(
                    (url, name) => existing[name] || new_file({ url, name }),
                    s.filenames
                )
            )
        )
            .then(upload_files)
            .then(wait_files)

        await start_request(
            add_b64_to_url(url, s.data) +
                "&" +
                url_query_encode({
                    actions: keys(actions),
                    files: map(f => f.remote_name, files)
                }),
            {
                csrfmiddlewaretoken: settings_get("settings", "csrf_token")
            }
        )
    }
}

export default ([name, ...args], data) => actions[name](data, ...args)
