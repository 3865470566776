import identity from "rfuncs/functions/identity"
import object_map from "rfuncs/functions/object_map"
import encode from "workflow/utils/encode"

export const DEFAULT_VIEW = "home"
export const MENU_VIEW = "menu"
export const SEARCH_VIEW = "search"
export const SLIDESHOW_VIEW = "slideshow"
export const MARKDOWN_VIEW = "markdown"
export const FILECHECK_VIEW = "filecheck"

const query = (name, component) => ({
    name,
    component,
    navigate: query => ({
        name,
        query
    }),
    path: `/${name}`,
    props: route => route.query
})
const b64 = (name, component) => ({
    name,
    component,
    navigate: params => ({
        name,
        params: { b64: encode.dumps(params) }
    }),
    path: `/${name}/:b64`,
    props: route => encode.loads(route.params.b64)
})

const routes = [
    query(DEFAULT_VIEW, null),
    query(
        MENU_VIEW,
        () =>
            import(/* webpackChunkName: "navigation" */ "js/routes/views/menu")
    ),
    query(
        SEARCH_VIEW,
        () =>
            import(
                /* webpackChunkName: "navigation" */ "js/routes/views/search"
            )
    ),
    b64(
        SLIDESHOW_VIEW,
        () =>
            import(
                /* webpackChunkName: "slideshow" */ "js/routes/views/slideshow"
            )
    ),
    b64(
        MARKDOWN_VIEW,
        () =>
            import(
                /* webpackChunkName: "markdown" */ "js/routes/views/markdown"
            )
    ),
    b64(
        FILECHECK_VIEW,
        () =>
            import(
                /* webpackChunkName: "filecheck" */ "js/routes/views/pdfview/pdfmodal"
            )
    )
]

export default object_map(p => p.name, identity, routes)
