import ready from "js/api/ready"
import registry from "js/api/registry"
import append from "js/dom/append"

export const make_promise = url =>
    new Promise((resolve, reject) => {
        const script = document.createElement("script")
        script.onload = () => resolve()
        script.src = url

        append("body", script)
    })

const promises = {}

const cached_promise = url => {
    if (promises[url]) return promises[url]
    return (promises[url] = make_promise(url))
}

export default (name, url, func) => {
    if (!registry[name]) {
        registry[name] = () => cached_promise(url)
    }
    if (func) ready(name, func)
}
