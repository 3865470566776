import Carousel from "bootstrap.native/src/components/carousel-native"
import Dropdown from "bootstrap.native/src/components/dropdown-native"
import Popover from "bootstrap.native/src/components/popover-native"
import closest from "js/dom/closest"
import ensure_id from "js/dom/ensure_id"
import on from "js/dom/on"
import selector from "js/dom/selector"
import toggle_class from "js/dom/toggle_class"
import can_hover from "js/features/can_hover"
import register_dom_update from "js/navigation/register_dom_update"
import all from "rfuncs/functions/all"
import filter from "rfuncs/functions/filter"
import first from "rfuncs/functions/first"
import map from "rfuncs/functions/map"
import scan from "rfuncs/functions/scan"
//import Button from "bootstrap.native/src/components/button-native"
//import Collapse from "bootstrap.native/src/components/collapse-native"
//import Modal from "bootstrap.native/src/components/modal-native"
//import ScrollSpy from "bootstrap.native/src/components/scrollspy-native"
//import Tab from "bootstrap.native/src/components/tab-native"
//import Toast from "bootstrap.native/src/components/toast-native"
//import Tooltip from "bootstrap.native/src/components/tooltip-native"

export function dismiss_dropdowns(query) {
    scan(
        e => {
            const ddown = Dropdown.getInstance(e)
            if (ddown.open) ddown.hide()
        },
        selector(query || ".bootstrap-dropdown")
    )
}

on("show.bs.dropdown", null, target => {
    dismiss_dropdowns(
        '.bootstrap-dropdown:not([data-uuid="' + ensure_id(target) + '"])'
    )
})

on("click", null, target => {
    if (
        !closest(target, ".dropdown-menu,.dropdown-toggle,.bootstrap-dropdown")
    ) {
        dismiss_dropdowns()
    }
})

function Button(el) {
    const elements = selector("input", el)
    const is_radio = all(elements, a => a.type == "radio")

    scan(e => {
        toggle_class(closest(e, ".btn"), { active: e.checked })

        e.addEventListener("change", () => {
            toggle_class(closest(e, ".btn"), { active: e.checked })

            if (is_radio) {
                toggle_class(
                    map(
                        t => closest(t, ".btn"),
                        filter(t => t !== e, elements)
                    ),
                    { active: false }
                )
            }
        })
    }, elements)

    return el
}

function Alert(el) {
    const cls = "." + el.getAttribute("data-dismiss")
    el.addEventListener("click", () => closest(el, cls).remove())
}

// function Slide(el) {
//     scan(
//         img =>
//             img.addEventListener("click", () => {
//                 const src = img.getAttribute("data-src")
//                 if (src) img.setAttribute("src", src)
//                 swap(first(selector("img", el)), img)
//             }),
//         selector("img", el)
//     )
// }

function Link(el) {
    el.setAttribute("href", el.getAttribute("data-href"))
}

function Copy(el) {
    const copy = first(selector(el.getAttribute("data-copy")))

    if (copy) {
        el.innerHTML = copy.innerHTML
    }
}

const components = [
    [Alert, "data-dismiss", null],
    [Button, "data-toggle", "buttons"],
    [Carousel, "data-ride", "carousel"],
    [Dropdown, "data-toggle", "dropdown"],
    //[Slide, "data-slide", "slide"],
    [Link, "data-href", null],
    [Copy, "data-copy", null]
]

if (can_hover()) {
    components.push([Popover, "data-toggle", "popover"])
}

register_dom_update({
    after: () => {
        scan(([comp, attr, match]) => {
            scan(
                e => {
                    new comp(e)
                    e.removeAttribute(attr)
                    if (match) toggle_class(e, { ["bootstrap-" + match]: true })
                },
                selector("[" + attr + (match ? '="' + match + '"' : "") + "]")
            )
        }, components)
    },
    before: () =>
        scan(e => toggle_class(e, { show: false }), selector(".popover.show"))
})
