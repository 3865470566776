import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "container",
  class: "onhover",
  style: {"position":"relative"}
}
const _hoisted_2 = ["name", "placeholder"]
const _hoisted_3 = {
  class: "onhover-show-visibility border border-top-0 border-end-0 bg-white ps-2 pe-2",
  style: {"position":"absolute","top":"1px","right":"1px","cursor":"pointer"}
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("textarea", {
      ref: "main",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.content_mutable) = $event)),
      class: "form-control",
      name: $props.name,
      placeholder: $props.placeholder,
      style: {"min-height":"200px"}
    }, null, 8 /* PROPS */, _hoisted_2), [
      [_vModelText, $data.content_mutable]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("a", {
        class: "small text-muted clickable",
        onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.open_editor && $options.open_editor(...args)), ["prevent"]))
      }, _toDisplayString($options.translations.actions.CHANGE) + " →", 1 /* TEXT */)
    ])
  ], 512 /* NEED_PATCH */))
}