// prettier-ignore
export default {
    "DOWNLOAD_TEMPLATE_OR_CHECK": "T\u00e9l\u00e9chargez le Gabarit ou v\u00e9rifiez le fichier",
    "DOWNLOAD_TEMPLATE": "T\u00e9l\u00e9chargez le gabrit",
    "CHECK_YOUR_FILE": "V\u00e9rifiez le Fichier",
    "TEMPLATE_FEATURE_1": "Obtenez le pagin\u00e9 du produit configur\u00e9 au format PDF et InDesign.",
    "TEMPLATE_FEATURE_2": "Usez-le pour ins\u00e9rer votre graphisme correctement.",
    "TEMPLATE_FEATURE_3": "Si vous avez un ou plusieurs fichiers d\u2019impression vous pouvez les v\u00e9rifier en les uploadant ci-dessous.",
    "TEMPLATE_FEATURE_4": "Vous pourrez g\u00e9rer la s\u00e9quence des pages et adapter le format.",
    "FILE_NAME": "Nom fichier",
    "RESIZE": "Redimensionnez",
    "SCALE": "\u00c9chelle",
    "CONTAIN": "Contenez",
    "FILL": "Remplissez",
    "SKETCH": "D\u00e9formez",
    "BLEED_AREA": "Zone du fond perdu",
    "CUT_AREA": "Zone de coupe",
    "EXPECTED_FORMAT": "Format attendu",
    "FILE_FORMAT": "Format du fichier",
    "PAGES": "Pages",
    "FILE": "Fichier",
    "INFORMATIONS": "Informations",
    "IMPOSITION": "Imposition",
    "FILECHECK": "File check",
    "EXTRA_PAGES": "Pages en exc\u00e8s",
    "GUIDES": "Guides",
    "PRINT_FILE": "Fichier d'impression"
}