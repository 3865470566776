import download_file from "js/files/actions/download_file"
import component from "js/legacy/unavailable-message"
import navigate from "js/routes/navigate"
import { defineComponent } from "vue"

export default defineComponent({
    extends: component,
    props: ["imposition_url"],
    methods: {
        initial_action() {
            download_file(this.imposition_url.replace(/\/json\//g, "/pdf/"))
            navigate()
        }
    }
})
