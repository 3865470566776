import from_error from "js/utils/from_error"
import get_django_url from "js/utils/get_django_url"
import map from "rfuncs/functions/map"
import object_map from "rfuncs/functions/object_map"
import { url_query_encode } from "workflow/utils/urlutilities"

const traceback_keys = {
    fileName: "file",
    functionName: "function",
    source: "source"
}

export default async error => {
    console.info("serializing error", error)

    const url = await get_django_url("notify_frontend_error")
    const traceback = await from_error(error)

    if (traceback) {
        const data = object_map(
            (alias, original) => alias,
            (alias, original) => map(d => d[original], traceback),
            traceback_keys
        )
        data["title"] = `${error}`
        data["user_agent"] = navigator.userAgent

        return await fetch(`${url}?${url_query_encode(data)}`)
    }
}
