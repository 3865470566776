import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  class: "position-fixed bottom-0 w-100 pe-none",
  style: {"z-index":"3000"}
}
const _hoisted_2 = { class: "row g-0 justify-content-center" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "d-flex shadow mb-0 py-2 px-1 bg-white position-absolute w-100 bottom-0 rounded-top border" }
const _hoisted_7 = {
  key: 0,
  class: "progress w-100 mx-1"
}
const _hoisted_8 = {
  class: "position-absolute ms-2 text-white",
  style: {"mix-blend-mode":"difference"}
}
const _hoisted_9 = {
  key: 1,
  class: "text-success"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "col-12 col-lg-6 position-relative",
        onMouseleave: _cache[0] || (_cache[0] = $event => ($options.toggle_hover(false))),
        onMouseover: _cache[1] || (_cache[1] = $event => ($options.toggle_hover(true)))
      }, [
        _createVNode(_TransitionGroup, { name: "message" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.messages, (m, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: m.pk,
                class: _normalizeClass(["alert clickable m-0 mb-4 shadow text-center position-absolute bottom-0 w-100 pe-auto d-flex justify-content-center align-items-center lead", 'alert-' + (m.class || 'success')]),
                style: _normalizeStyle({
                            transform: `scale(${_ctx.message_scale[i]}, ${_ctx.message_scale[i]}) translate(0, ${_ctx.message_translate[i]})`
                        }),
                onClick: $event => (_ctx.message_remove(m.pk))
              }, [
                _createElementVNode("span", {
                  innerHTML: m.content
                }, null, 8 /* PROPS */, _hoisted_4),
                (m.href)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      class: "stretched-link",
                      href: m.href,
                      target: "_blank"
                    }, null, 8 /* PROPS */, _hoisted_5))
                  : _createCommentVNode("v-if", true)
              ], 14 /* CLASS, STYLE, PROPS */, _hoisted_3))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        })
      ], 32 /* NEED_HYDRATION */),
      _createElementVNode("div", {
        class: _normalizeClass(["col-12 col-lg-7 position-relative progress-tracking", { 'progress-loading': _ctx.is_loading }])
      }, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_TransitionGroup, { name: "tracker" }, {
            default: _withCtx(() => [
              ($options.length(_ctx.trackers) == 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7))
                : _createCommentVNode("v-if", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.trackers, (tracker, i) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: i,
                  class: "progress text-start bg-light position-relative progress-bar-striped progress-bar-animated mx-1",
                  style: _normalizeStyle({
                                width: `${tracker.width * 100}%`
                            })
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tracker.status, (status, i) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: i,
                      class: _normalizeClass(["progress-bar", {
                                    'bg-danger': i == 'danger',
                                    'progress-bar-striped progress-bar-animated':
                                        i == 'loading'
                                }]),
                      style: _normalizeStyle({ width: `${status.width * 100}%` })
                    }, null, 6 /* CLASS, STYLE */))
                  }), 128 /* KEYED_FRAGMENT */)),
                  _createElementVNode("div", _hoisted_8, [
                    _createTextVNode(_toDisplayString(tracker.title) + " ", 1 /* TEXT */),
                    (
                                        tracker.aggregated.done <
                                        tracker.aggregated.total
                                    )
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(" … ")
                        ], 64 /* STABLE_FRAGMENT */))
                      : (_openBlock(), _createElementBlock("span", _hoisted_9, "✓"))
                  ])
                ], 4 /* STYLE */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          })
        ])
      ], 2 /* CLASS */)
    ])
  ]))
}