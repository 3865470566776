import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "modal-content position-relative rounded" }
const _hoisted_2 = { class: "carousel carousel-dark slide h-100 rounded" }
const _hoisted_3 = { class: "carousel-inner w-100 h-100 rounded" }
const _hoisted_4 = ["src", "title"]
const _hoisted_5 = { class: "carousel-indicators" }
const _hoisted_6 = ["title", "onClick"]
const _hoisted_7 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade show opacity-100 modal p-3 bg-glass-dark d-flex align-items-center justify-content-center",
    onClick: _cache[4] || (_cache[4] = (...args) => (_ctx.close && _ctx.close(...args)))
  }, [
    _createElementVNode("div", {
      class: "modal-dialog modal-fullscreen bg-white rounded",
      style: {"height":"calc(100vh - 50px)","width":"calc(100vw - 50px)"},
      onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              class: "carousel-item active img-cover w-100 h-100 rounded",
              src: _ctx.current.src,
              title: _ctx.current.title
            }, null, 8 /* PROPS */, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
              return (_openBlock(), _createElementBlock("button", {
                class: _normalizeClass({ active: image.src == _ctx.current.src }),
                "data-bs-target": "",
                title: image.title,
                type: "button",
                onClick: $event => ($options.goto(index))
              }, null, 10 /* CLASS, PROPS */, _hoisted_6))
            }), 256 /* UNKEYED_FRAGMENT */))
          ]),
          _createElementVNode("button", {
            class: "carousel-control-prev",
            type: "button",
            onClick: _cache[0] || (_cache[0] = _withModifiers((...args) => ($options.prev && $options.prev(...args)), ["prevent"]))
          }, _cache[5] || (_cache[5] = [
            _createElementVNode("span", { class: "carousel-control-prev-icon" }, null, -1 /* HOISTED */)
          ])),
          _createElementVNode("button", {
            class: "carousel-control-next",
            type: "button",
            onClick: _cache[1] || (_cache[1] = _withModifiers((...args) => ($options.next && $options.next(...args)), ["prevent"]))
          }, _cache[6] || (_cache[6] = [
            _createElementVNode("span", { class: "carousel-control-next-icon" }, null, -1 /* HOISTED */)
          ]))
        ]),
        _createElementVNode("button", {
          class: "btn-close position-absolute top-0 end-0 mx-4 my-4 p-2 rounded-circle border bg-light",
          style: {"z-index":"100000"},
          title: _ctx.translations.actions.CLOSE,
          type: "button",
          onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.close && _ctx.close(...args)))
        }, null, 8 /* PROPS */, _hoisted_7)
      ])
    ])
  ]))
}