import { make_promise } from "js/api/load"
import register_dom_update from "js/navigation/register_dom_update"
import state from "js/state/main"
import user from "js/state/user"
import first from "rfuncs/functions/first"
import length from "rfuncs/functions/length"
import merge from "rfuncs/functions/merge"
import scan from "rfuncs/functions/scan"
import hash from "workflow/utils/hash"

// <script>
//   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//   '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//   })(window,document,'script','%(data_layer_name)s','%(tracking_id)s');
// </script>

let resolve = null

const promise = new Promise(r => (resolve = r))

window.dataLayer = []

function gtag() {
    if (length(arguments) > 1) {
        window.dataLayer.push(arguments)
    } else {
        window.dataLayer.push(first(arguments))
    }
    window.dataLayer.push(function () {
        this.reset()
    })
}

const make_ready =
    (...funcs) =>
    (...args) => {
        promise.then(() => scan(f => f(...args), funcs))
    }

const add_globals = (...args) =>
    merge(
        {
            user_id: user.pk ? hash(user.pk) : null,
            user_staff: user.is_staff ? true : false
        },
        state.settings,
        ...args
    )

const gtag_init = async id => {
    await make_promise("https://www.googletagmanager.com/gtm.js?id=" + id).then(
        resolve
    )

    gtag(
        "config",
        id,
        add_globals({
            send_page_view: false
        })
    )
    // gtag("consent", "default", consent.default)
    gtag(add_globals({ "gtm.start": new Date().getTime(), event: "gtm.js" }))
}

export default {
    load: (...args) => gtag_init(...args),
    gtag: make_ready((...args) => gtag(add_globals(...args)))
}
